import React, { useState, useEffect, ChangeEvent, FormEvent, useContext } from 'react';
import { ACTIVE_MEDIA_URL, ACTIVE_REDIRECT_URL, ACTIVE_URL } from '../../constants';
import { BagContext } from '../../utils/CartContext/CartContext';
import { getCookie } from '../../utils/GetCookie/GetCookie';
import { Link } from 'react-router-dom';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../shadcn/components/ui/accordion";

const Checkout = () => {
    const { bagItems, bagInfo, bagId } = useContext(BagContext) || { numItems: 0 };

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        zipCode: '',
        phoneNumber: '',
        email: '',
        terms: false,
    });

    const [digest, setDigest] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        zipCode: '',
        phoneNumber: '',
        email: '',
        terms: '',
    });

    const [mobileBagOpen, setMobileBagOpen] = useState(false);

    useEffect(() => {
        const validateForm = () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isEmailValid = emailRegex.test(form.email);
            const isZipCodeValid = /^\d{5}$/.test(form.zipCode);
            const isPhoneNumberValid = /^\d{10}$/.test(form.phoneNumber);
            const newErrors = {
                firstName: form.firstName ? '' : 'First name is required.',
                lastName: form.lastName ? '' : 'Last name is required.',
                address: form.address ? '' : 'Address is required.',
                city: form.city ? '' : 'City is required.',
                zipCode: isZipCodeValid ? '' : 'Zip code must be 5 digits.',
                phoneNumber: isPhoneNumberValid ? '' : 'Phone number must be 10 digits.',
                email: isEmailValid ? '' : 'Please enter a valid email address.',
                terms: form.terms ? '' : 'You must agree to the terms.',
            };
            setErrors(newErrors);
        };

        if (submitted) {
            validateForm();
        }
    }, [form, submitted]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        if (name === 'zipCode' || name === 'phoneNumber') {
            const newValue = value.replace(/\D/g, ''); // Remove non-numeric characters
            const maxLength = name === 'zipCode' ? 5 : 10;
            if (newValue.length <= maxLength) {
                setForm((prevForm) => ({
                    ...prevForm,
                    [name]: newValue,
                }));
            }
        } else {
            setForm((prevForm) => ({
                ...prevForm,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitted(true);
        const success = await fetchDigest();
    };


    const fetchDigest = async () => {
        try {
            const params = new URLSearchParams({
                shippingFirstName: form.firstName,
                shippingLastName: form.lastName,
                version: '2',
                mid: '8000007532',
                lang: 'en',
                deviceCategory: '0', // assuming PC, change dynamically if needed
                orderid: getCookie('bag_id'),
                orderDesc: 'Regular Order',
                orderAmount: bagInfo?.total?.toString() || '0',
                currency: 'EUR',
                billCountry: 'GR',
                billAddress: form.address,
                billCity: form.city,
                zipCode: form.zipCode,
                payerPhone: form.phoneNumber,
                payerEmail: form.email,
                confirmUrl: `${ACTIVE_REDIRECT_URL}/order_completed/`,
                cancelUrl: `${ACTIVE_REDIRECT_URL}/order_canceled/`,
            });

            const response = await fetch(`${ACTIVE_URL}/api/orders/create_digest/?${params.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.digest);
                (document.getElementById('digest') as HTMLInputElement).value = data.digest;
                (document.getElementById('checkoutForm') as HTMLFormElement).submit();
                return true; // Return success
            } else {
                console.error('Failed to fetch digest');
                return false; // Return failure
            }
        } catch (error) {
            console.error('Error fetching digest:', error);
            return false; // Return failure
        }
    };

    if (bagItems && bagItems.length === 0) {
        return (
            <div className='min-h-screen bg-main flex items-center justify-center select-none flex flex-col gap-4'>
                <h3 className='text-lg'>Your shopping bag is empty</h3>
                <Link to='/'>
                    <button className='bg-black text-white px-4 py-2 rounded-sm'>Continue Shopping</button>
                </Link>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center py-28">
            <div className="w-[320px] m-auto">
                <div className="w-full flex justify-between text-xs text-neutral-500 mb-4">
                    <div>SHIPPING</div>
                    <div>*REQUIRED</div>
                </div>
                <form action='https://ecommerce.cardlink.gr/vpos/shophandlermpi' method="POST" id="checkoutForm" onClick={handleSubmit}>

                    <input name="version" id="version" type="hidden" value="2" />
                    <input name="mid" id="mid" type="hidden" value="8000007532" />
                    <input name="lang" id="lang" type="hidden" value="en" />
                    <input name="deviceCategory" id="deviceCategory" type="hidden" value="0" />
                    <input name="orderid" id="orderid" type="hidden" value={getCookie('bag_id')} />
                    <input name="orderDesc" id="orderDesc" type="hidden" value="Regular Order" />
                    <input name="orderAmount" id="orderAmount" type="hidden" value={bagInfo?.total?.toString()} />
                    <input name="currency" id="currency" type="hidden" value="EUR" />
                    <input name="billCountry" id="billCountry" type="hidden" value="GR" />
                    <input name="confirmUrl" id="confirmUrl" type="hidden" value={`${ACTIVE_REDIRECT_URL}/order_completed/`} />
                    <input name="cancelUrl" id="cancelUrl" type="hidden" value={`${ACTIVE_REDIRECT_URL}/order_canceled/`} />
                    <input name="digest" id="digest" type="hidden" value="" />

                    <div className="mb-4">
                        <input
                            type="text"
                            id="shippingFirstName"
                            name="shippingFirstName"
                            placeholder="First Name *"
                            required
                            className="mt-2 px-1 py-1 bg-white border-b border-neutral-300 placeholder-neutral-400 block w-full focus:outline-none"
                            value={form.firstName}
                            onChange={handleInputChange}
                        />
                        {submitted && <p className="text-red-500 text-sm">{errors.firstName || ' '}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="shippingLastName"
                            name="shippingLastName"
                            placeholder="Last Name *"
                            required
                            className="mt-2 px-1 py-2 bg-white border-b border-neutral-300 placeholder-neutral-400 block w-full focus:outline-none"
                            value={form.lastName}
                            onChange={handleInputChange}
                        />
                        {submitted && <p className="text-red-500 text-sm">{errors.lastName || ' '}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="billAddress"
                            name="billAddress"
                            placeholder="Address *"
                            required
                            className="mt-2 px-1 py-2 bg-white border-b border-neutral-300 placeholder-neutral-400 block w-full focus:outline-none"
                            value={form.address}
                            onChange={handleInputChange}
                        />
                        {submitted && <p className="text-red-500 text-sm">{errors.address || ' '}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="billCity"
                            name="billCity"
                            placeholder="City *"
                            required
                            className="mt-2 px-1 py-2 bg-white border-b border-neutral-300 placeholder-neutral-400 block w-full focus:outline-none"
                            value={form.city}
                            onChange={handleInputChange}
                        />
                        {submitted && <p className="text-red-500 text-sm">{errors.city || ' '}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="billZip"
                            name="billZip"
                            placeholder="Zip Code *"
                            required
                            maxLength={5}
                            className="mt-2 px-1 py-2 bg-white border-b border-neutral-300 placeholder-neutral-400 block w-full focus:outline-none"
                            value={form.zipCode}
                            onChange={handleInputChange}
                        />
                        {submitted && <p className="text-red-500 text-sm">{errors.zipCode || ' '}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="payerPhone"
                            name="payerPhone"
                            placeholder="Phone Number *"
                            required
                            maxLength={10}
                            className="mt-2 px-1 py-2 bg-white border-b border-neutral-300 placeholder-neutral-400 block w-full focus:outline-none"
                            value={form.phoneNumber}
                            onChange={handleInputChange}
                        />
                        {submitted && <p className="text-red-500 text-sm">{errors.phoneNumber || ' '}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="email"
                            id="payerEmail"
                            name="payerEmail"
                            placeholder="Email *"
                            required
                            className="mt-2 px-1 py-2 bg-white border-b border-neutral-300 placeholder-neutral-400 block w-full focus:outline-none"
                            value={form.email}
                            onChange={handleInputChange}
                        />
                        {submitted && <p className="text-red-500 text-sm">{errors.email || ' '}</p>}
                    </div>
                    <div className="mt-6 mb-8 flex items-center">
                        <input
                            type="checkbox"
                            id="terms"
                            name="terms"
                            required
                            className="h-4 w-4 text-white accent-neutral-700 bg-white border-gray-300"
                            checked={form.terms}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="terms" className="ml-2 block text-sm text-neutral-600">
                            I agree with the <a href="#" className="underline">terms of service</a> and <a href="#" className="underline">privacy policy</a>.
                        </label>
                        {submitted && <p className="text-red-500 text-sm">{errors.terms || ' '}</p>}
                    </div>
                    <div className='block lg:hidden'>
                        <Accordion type="single" value={mobileBagOpen ? 'item-1' : ''} onValueChange={() => setMobileBagOpen(!mobileBagOpen)} collapsible className={`${mobileBagOpen ? '' : '' } bg-beige duration-100 border rounded-md px-2 shadow-md`} >
                            <AccordionItem value="item-1" onSelect={()=>setMobileBagOpen(true)} >
                                <AccordionTrigger>Bag Overview</AccordionTrigger>
                                <AccordionContent>
                                    <div>
                                        <div className="flex bg-beige flex-col justify-between w-full  py-2 h-full rounded-md">

                                            <div className=" border-b border-neutral-300 py-2 flex flex-col gap-2 text-sm">
                                                <div className="w-full flex justify-between items-center px-0.5">
                                                    <div>
                                                        Subtotal
                                                    </div>
                                                    <div>
                                                        &euro; {bagInfo ? bagInfo.subtotal : 0}
                                                    </div>
                                                </div>
                                                <div className="w-full flex justify-between items-center px-0.5">
                                                    <div>
                                                        Express (3-5 days)
                                                    </div>
                                                    <div>
                                                        &euro; {bagInfo ? bagInfo.shipping : 0}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-b border-neutral-300 flex flex-col gap-2 text-sm py-2 mb-4">
                                                <div className="w-full flex justify-between items-center px-0.5">
                                                    <div>
                                                        Total
                                                    </div>
                                                    <div>
                                                        &euro; {bagInfo ? bagInfo.total : 0}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-4 h-full overflow-y-scroll ">
                                                {bagItems && bagItems.map((item, index) => (
                                                    <div key={index} className="grid grid-cols-2 gap-2">
                                                        <img src={ACTIVE_MEDIA_URL + item.image_url} alt={item.name} className="rounded-sm" />
                                                        <div className="flex flex-col justify-between">
                                                            <div className="text-lg">{item.name}</div>
                                                            <div className="flex-col flex gap-0.5">
                                                                <div>Size: {item.size}</div>
                                                                <div>Color: {item.color} </div>
                                                                <div className='flex gap-1 items-center justify-start'>
                                                                    <div>
                                                                        Quantity:
                                                                    </div>
                                                                    {item.quantity}
                                                                </div>
                                                                <div>Price: &euro;{item.price}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                                {bagItems && bagItems.length === 0 ? <div className="text-center h-full flex items-center justify-center">Your bag is empty</div> : null}
                                            </div>

                                        </div>
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>

                    </div>
                    <div className="mt-6">
                        <button
                            type="submit"
                            className="w-full px-4 py-3.5 text-lg font-medium text-white bg-neutral-800 rounded-sm shadow-sm hover:bg-neutral-900 focus:outline-none"
                        >
                            Place Order
                        </button>
                    </div>
                </form>
            </div >
        </div >
    );
};

export default Checkout;
