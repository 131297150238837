import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getCookie } from "../../utils/GetCookie/GetCookie";

const OrderCouldNotBeCompletedPage = () => {
    const { order_id } = useParams<{ order_id?: string }>();

    const rebuildOrder = () => {
        const bag_id = getCookie('bag_id');
        
        document.cookie = 'bag_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    }

    useEffect(() => {
        rebuildOrder();
    }, []);

    return (
        <div className="h-screen py-10 flex items-center justify-center">
            <div className="container mx-auto flex flex-col items-center justify-center">
                <h1 className="text-2xl font-bold">Your order could not be completed</h1>
                <p className=" mt-4">It seems that you encountered a problem with the playment provider. </p>
                <Link to='/' className="bg-black text-white px-5 py-2 mt-4">
                    Continue Shopping
                </Link>
            </div>
        </div>
    )
}

export default OrderCouldNotBeCompletedPage;