import Checkout from "../../Components/Checkout/Checkout";


const CheckoutPage = () => {


    return (
        <div>
            <Checkout />
        </div>
    )
}

export default CheckoutPage;