import { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { ACTIVE_URL, ACTIVE_MEDIA_URL } from '../../constants';
import { IonSpinner } from '@ionic/react';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../shadcn/components/ui/accordion";
import { BagInfo, BagItem, OrderStatus } from "../../interfaces";
import StatusLines from "./StatusLines/StatusLines";

const OrderConfirmPage = () => {
    const { order_id } = useParams<{ order_id?: string }>();
    const [orderCompleted, setOrderCompleted] = useState<boolean>(false);
    const [orderStatus, setOrderStatus] = useState<OrderStatus>(null);
    const [bagOpen, setBagOpen] = useState(false);
    const [bagItems, setBagItems] = useState<BagItem[]>([]);
    const [bagInfo, setBagInfo] = useState<BagInfo>({
        total_qnty: 0,
        subtotal: 0,
        total: 0,
        shipping: 0,
        vat: 0
    });
    const [isLoading, setIsLoading] = useState(true);

    const fetchOrderData = useCallback(async () => {
        if (!order_id) return;

        try {
            const [completeResponse, statusResponse, itemsResponse] = await Promise.all([
                fetch(`${ACTIVE_URL}/api/orders/is_order_completed/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Bag-Id': order_id
                    }
                }),
                fetch(`${ACTIVE_URL}/api/orders/get_order_status/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Bag-Id': order_id
                    }
                }),
                fetch(`${ACTIVE_URL}/api/orders/get_completed_bag_items/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Bag-Id': order_id
                    }
                })
            ]);

            const [completeData, statusData, itemsData] = await Promise.all([
                completeResponse.json(),
                statusResponse.json(),
                itemsResponse.json()
            ]);

            setOrderCompleted(completeData.completed);
            setOrderStatus(statusData.status as OrderStatus);

            if (itemsResponse.ok) {
                setBagInfo({
                    total_qnty: itemsData.total_qnty,
                    subtotal: itemsData.total,
                    total: itemsData.total_with_shipping,
                    shipping: itemsData.shipping_cost,
                    vat: itemsData.total_vat
                });
                setBagItems(itemsData.orderproduct_set.map((item: any) => ({
                    item_sku: item.variant_sku,
                    quantity: item.quantity,
                    price: parseFloat(item.price.toFixed(2)),
                    image_url: item.variant_image,
                    name: item.product_name,
                    color: item.color_name,
                    item_total: parseInt(item.quantity.toFixed(2)) * parseFloat(item.price.toFixed(2)),
                    size: item.size_name,
                    brand: item.brand_name
                })));
            }
        } catch (error) {
            console.error('Error fetching order data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [order_id]);

    useEffect(() => {
        fetchOrderData();
    }, [fetchOrderData]);

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <IonSpinner name="crescent" />
            </div>
        );
    }

    return (
        <div className="min-h-screen flex flex-col pt-32">
            <div className="max-w-[400px] m-auto flex-grow flex flex-col justify-center p-4">
                {orderCompleted ? (
                    <>
                        <h3 className="text-3xl font-normal mb-10">Thank you for your order!</h3>
                        <p className="mb-4">Order Unique Code: {order_id}</p>

                        {orderStatus && (
                            <>
                                <p className="mb-5">Order Status: {orderStatus}</p>
                                <StatusLines status={orderStatus} />
                            </>
                        )}

                        <p className="text-sm text-gray-600 mt-4">
                            If you have any questions or concerns about your order, please feel free to contact us at info@moncboutique.gr
                        </p>

                        <div className='block mt-4'>
                            <Accordion type="single" value={bagOpen ? 'item-1' : ''} onValueChange={() => setBagOpen(!bagOpen)} collapsible className={`bg-beige duration-100 border rounded-md px-2 shadow-md`}>
                                <AccordionItem value="item-1" onSelect={() => setBagOpen(true)}>
                                    <AccordionTrigger>Bag Overview</AccordionTrigger>
                                    <AccordionContent>
                                        <div className="flex bg-beige flex-col justify-between w-full py-2 h-full rounded-md">
                                            <div className="border-b border-neutral-300 py-2 flex flex-col gap-2 text-sm">
                                                <div className="w-full flex justify-between items-center px-0.5">
                                                    <div>Subtotal</div>
                                                    <div>&euro; {bagInfo.subtotal}</div>
                                                </div>
                                                <div className="w-full flex justify-between items-center px-0.5">
                                                    <div>Express (3-5 days)</div>
                                                    <div>&euro; {bagInfo.shipping}</div>
                                                </div>
                                            </div>
                                            <div className="border-b border-neutral-300 flex flex-col gap-2 text-sm py-2 mb-4">
                                                <div className="w-full flex justify-between items-center px-0.5">
                                                    <div>Total</div>
                                                    <div>&euro; {bagInfo.total}</div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-4 h-full overflow-y-scroll">
                                                {bagItems.map((item, index) => (
                                                    <div key={index} className="grid grid-cols-2 gap-2">
                                                        <img src={ACTIVE_MEDIA_URL + item.image_url} alt={item.name} className="rounded-sm" />
                                                        <div className="flex flex-col justify-between">
                                                            <div className="text-lg">{item.name}</div>
                                                            <div className="flex-col flex gap-0.5">
                                                                <div>Size: {item.size}</div>
                                                                <div>Color: {item.color}</div>
                                                                <div className='flex gap-1 items-center justify-start'>
                                                                    <div>Quantity:</div>
                                                                    {item.quantity}
                                                                </div>
                                                                <div>Price: &euro;{item.price}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {bagItems.length === 0 && <div className="text-center h-full flex items-center justify-center">The bag is empty</div>}
                                            </div>
                                        </div>
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                        </div>

                        <Link to='/' className="mt-6 mb-10">
                            <button className="bg-black text-white py-2 px-4 rounded-sm">Back to Home</button>
                        </Link>
                    </>
                ) : (
                    <div className="text-center text-lg">
                        <p className="mb-4">
                            Order with id "{order_id}" does not exist or has not been completed yet.
                        </p>
                        <Link to='/'>
                            <button className="bg-black text-white py-2 px-4 rounded-sm">Continue Shopping</button>
                        </Link>
                        <p className="mt-6 text-sm text-gray-600">
                            If you're experiencing any issues, please don't hesitate to contact us at info@moncboutique.gr
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default OrderConfirmPage;