import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { changeBagId, getCookie } from '../GetCookie/GetCookie';
import { ACTIVE_URL } from '../../constants';
import { IonIcon } from '@ionic/react';
import { BagContextType, BagInfo, BagItem } from '../../interfaces';



const BagContext = createContext<BagContextType | undefined>(undefined);

interface BagProviderProps {
    children: ReactNode;
}

const BagProvider: React.FC<BagProviderProps> = ({ children }) => {
    const [bagInfo, setBagInfo] = useState<BagInfo>({
        total_qnty: 0,
        subtotal: 0,
        total: 0,
        shipping: 0,
        vat: 0
    });
    const [bagItems, setBagItems] = useState<BagItem[]>([]);
    const [numItems, setNumItems] = useState<number>(0);
    const [addedItem, setAddedItem] = useState<number>(0);
    const [bagId, setBagId] = useState<string>('');

    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [popupMessage, setPopupMessage] = useState<string>('');
    const [popupTimeout, setPopupTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (popupTimeout) {
                clearTimeout(popupTimeout);
            }
        };
    }, [popupTimeout]);

    const fetchCart = async () => {
        try {
            const bag_id = getCookie('bag_id');
            setBagId(bag_id);
            if (bag_id) {
                const response = await fetch(`${ACTIVE_URL}/api/orders/get_bag_items/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Bag-Id': `${bag_id}`,
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setBagInfo({
                        total_qnty: data.total_qnty,
                        subtotal: data.total,
                        total: data.total_with_shipping,
                        shipping: data.shipping_cost,
                        vat: data.total_vat
                    });
                    setNumItems(data.total_qnty);
                    console.log(data)
                    let tempCart: BagItem[] = []
                    data.orderproduct_set.map((item: any) => {
                        tempCart.push({
                            item_sku: item.variant_sku,
                            quantity: item.quantity,
                            price: parseFloat(item.price.toFixed(2)),
                            image_url: item.variant_image,
                            name: item.product_name,
                            color: item.color_name,
                            item_total: parseInt(item.quantity.toFixed(2)) * parseFloat(item.price.toFixed(2)),
                            size: item.size_name,
                            brand: item.brand_name
                        })
                    })
                    setBagItems(tempCart);
                } else {
                    console.error('Failed to fetch cart:', data.message);
                    if (data.message === 'Order not found') {
                        const newBagId = changeBagId();
                        setBagId(newBagId);
                        fetchCart();
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
    };

    useEffect(() => {
        fetchCart();
    }, []);

    useEffect(() => {
        const savedState = localStorage.getItem('bagState');
        if (savedState) {
            const parsedState = JSON.parse(savedState);
            setBagInfo(parsedState.bagInfo);
            setBagItems(parsedState.bagItems);
            setNumItems(parsedState.numItems);
            setAddedItem(parsedState.addedItem);
            setBagId(parsedState.bagId);
        }
    }, []);

    useEffect(() => {
        const bagState = {
            bagInfo,
            bagItems,
            numItems,
            addedItem,
            bagId
        };
        localStorage.setItem('bagState', JSON.stringify(bagState));
    }, [bagInfo, bagItems, numItems, addedItem, bagId]);

    useEffect(() => {
        const syncBagState = (event: StorageEvent) => {
            if (event.key === 'bagState') {
                const savedState = event.newValue ? JSON.parse(event.newValue) : null;
                if (savedState) {
                    setBagInfo(savedState.bagInfo);
                    setBagItems(savedState.bagItems);
                    setNumItems(savedState.numItems);
                    setAddedItem(savedState.addedItem);
                    setBagId(savedState.bagId);
                }
            }
        };

        window.addEventListener('storage', syncBagState);

        return () => {
            window.removeEventListener('storage', syncBagState);
        };
    }, []);

    const showPopup = (message: string) => {
        setPopupMessage(message);
        setPopupVisible(true);
        const timeout = setTimeout(() => setPopupVisible(false), 3000);
        setPopupTimeout(timeout);
    };

    const reduceQuantity = async (item: BagItem, quantity: number) => {
        try {
            const bag_id = getCookie('bag_id');
            const response = await fetch(`${ACTIVE_URL}/api/orders/reduce_quantity/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    item_sku: item.item_sku,
                    quantity: quantity,
                    bag_id: bag_id
                })
            });

            const data = await response.json();
            if (response.ok) {
                setBagInfo({
                    total_qnty: data.total_qnty,
                    subtotal: data.total,
                    total: data.total_with_shipping,
                    shipping: data.shipping_cost,
                    vat: data.total_vat
                });

                setNumItems(data.total_qnty);
                let tempCart: BagItem[] = []
                data.orderproduct_set.map((item: any) => {
                    tempCart.push({
                        item_sku: item.variant_sku,
                        quantity: item.quantity,
                        price: item.price,
                        image_url: item.variant_image,
                        name: item.product_name,
                        color: item.color_name,
                        item_total: parseInt(item.quantity) * parseFloat(item.price),
                        size: item.size_name,
                        brand: item.brand_name
                    })
                })

                setBagItems(tempCart);
                setAddedItem(prev => prev + 1);
            } else {
                alert('Failed to add item:' + data.message);
            }
        } catch (error) {
            console.error('Error adding item:', error);
            alert('Failed to reduce item quantity');
        }
    };

    const increaseQuantity = async (item: BagItem, quantity: number) => {
        try {
            const bag_id = getCookie('bag_id');

            const response = await fetch(`${ACTIVE_URL}/api/orders/increase_quantity/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    item_sku: item.item_sku,
                    quantity: quantity,
                    bag_id: bag_id
                })
            });

            const data = await response.json();
            console.log(data)
            if (response.ok && data.orderproduct_set) {
                setBagInfo({
                    total_qnty: data.total_qnty,
                    subtotal: data.total,
                    total: data.total_with_shipping,
                    shipping: data.shipping_cost,
                    vat: data.total_vat
                });

                setNumItems(data.total_qnty);
                let tempCart: BagItem[] = []
                data.orderproduct_set.map((item: any) => {
                    tempCart.push({
                        item_sku: item.variant_sku,
                        quantity: item.quantity,
                        price: item.price,
                        image_url: item.variant_image,
                        name: item.product_name,
                        color: item.color_name,
                        item_total: parseInt(item.quantity) * parseFloat(item.price),
                        size: item.size_name,
                        brand: item.brand_name
                    })
                })

                setBagItems(tempCart);
                setAddedItem(prev => prev + 1);
            } else {
                console.log(data)
                const errorMessage = data.message || 'Failed to add item';
                showPopup(errorMessage);
            }
        } catch (error) {
            showPopup('Failed to increase item quantity');
        }
    };

    const removeItem = async (item: BagItem) => {
        try {
            const bag_id = getCookie('bag_id');

            const response = await fetch(`${ACTIVE_URL}/api/orders/remove_item/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    item_sku: item.item_sku,
                    bag_id: bag_id
                })
            });

            const data = await response.json();
            if (response.ok) {
                setBagInfo({
                    total_qnty: data.total_qnty,
                    subtotal: data.total,
                    total: data.total_with_shipping,
                    shipping: data.shipping_cost,
                    vat: data.total_vat
                });

                setNumItems(data.total_qnty);
                let tempCart: BagItem[] = []
                data.orderproduct_set.map((item: any) => {
                    tempCart.push({
                        item_sku: item.variant_sku,
                        quantity: item.quantity,
                        price: item.price,
                        image_url: item.variant_image,
                        name: item.product_name,
                        color: item.color_name,
                        item_total: parseInt(item.quantity) * parseFloat(item.price),
                        size: item.size_name,
                        brand: item.brand_name
                    })
                })

                setBagItems(tempCart);
                setAddedItem(prev => prev + 1);
            } else {
                alert('Failed to add item:' + data.message);
            }
        } catch (error) {
            console.error('Error adding item:', error);
            alert('Failed to increase item quantity');
        }
    };

    const addItem = async (item_sku: string, quantity: number) => {
        try {
            const bag_id = getCookie('bag_id');

            const response = await fetch(`${ACTIVE_URL}/api/orders/add_item/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    item_sku: item_sku,
                    quantity: quantity,
                    bag_id: bag_id
                })
            });

            const data = await response.json();
            if (response.ok) {
                setBagInfo({
                    total_qnty: data.total_qnty,
                    subtotal: data.total,
                    total: data.total_with_shipping,
                    shipping: data.shipping_cost,
                    vat: data.total_vat
                });

                setNumItems(data.total_qnty);
                let tempCart: BagItem[] = []
                data.orderproduct_set.map((item: any) => {
                    tempCart.push({
                        item_sku: item.variant_sku,
                        quantity: item.quantity,
                        price: item.price,
                        image_url: item.variant_image,
                        name: item.product_name,
                        color: item.color_name,
                        item_total: parseInt(item.quantity) * parseFloat(item.price),
                        size: item.size_name,
                        brand: item.brand_name
                    })
                })

                setBagItems(tempCart);
                setAddedItem(prev => prev + 1);
            } else {
                console.log(data)
                const errorMessage = data.message || 'Failed to add item';
                showPopup(errorMessage);
            }
        } catch (error) {
            showPopup('Failed to add item');
        }
    };

    return (
        <BagContext.Provider value={{ bagItems, numItems, addItem, addedItem, increaseQuantity, reduceQuantity, removeItem, bagId, bagInfo }}>
            {children}
            <div className={`w-full fixed duration-500 flex items-center justify-center ${popupVisible ? 'bottom-0' : 'bottom-[-100%]'}`}>
                <div className='w-[500px] my-2 py-5 rounded-sm shadow-lg border bg-beige flex items-center justify-center px-2 gap-2'>
                    <div className='h-5 w-5 rounded-full bg-red-500 flex items-center justify-center'>
                        <IonIcon icon='remove' className='text-neutral-50' />
                    </div>
                    {popupMessage}
                </div>
            </div>
        </BagContext.Provider>
    );
};

export { BagContext, BagProvider };