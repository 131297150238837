
import Visa from '../../../assets/images/general/visa-icon.png';
import MC from '../../../assets/images/general/mastercard-icon.png';

const Payments = () => {


    return (
        <div className="min-h-screen max-w-desktop m-auto py-12">
            <h3 className="text-3xl mb-6">
                Payments
            </h3>
            <div className="flex flex-col gap-2">
                <h4 className="font-semibold">
                    Debit / Credit Cards
                </h4>
                <div>
                    We Accept:
                </div>
                <div className='flex gap-2 items-center justify-center w-fit'>
                    <img src={Visa} alt="visa card logo image" className='h-4' />
                    Visa
                </div>
                <div className='flex gap-2 items-center justify-center w-fit'>
                    <img src={MC} alt="visa card logo image" className='h-4' />
                    Mastercard
                </div>
            </div>
        </div>
    )
}

export default Payments;