import { useEffect, useRef, useState } from "react";
import { ProductItemGridDetails, SearchPageProps } from "../../interfaces";
import { ACTIVE_URL } from "../../constants";
import ProductListItem from "../ProductList/ProductListItem/ProductListItem";
import { IonIcon } from "@ionic/react";
import { useNavigate, useParams } from 'react-router-dom';

const Search: React.FC<SearchPageProps> = () => {
    const { searchTerm } = useParams<{ searchTerm: string }>();
    const fetched = useRef<boolean>(false);
    const [search, setSearch] = useState<string>(searchTerm || '');
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string>(searchTerm || '');
    const [searchResults, setSearchResults] = useState<ProductItemGridDetails[]>([]);
    const navigate = useNavigate();

    const fetchResponses = async (searchTerm: string) => {
        try {
            const response = await fetch(`${ACTIVE_URL}/api/products/search/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Search-Term': searchTerm ?? ''
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setSearchResults([]);
                data.products.forEach((product: any) => {
                    const productDetails: ProductItemGridDetails = {
                        title: product.title,
                        image: product.image,
                        brand_name: product.brand_name,
                        category_name: product.category_name,
                        colors: product.colors.map((color: { code: string }) => color.code),
                        sizes: product.sizes.map((size: { code: string }) => size.code),
                        price: product.price,
                        on_sale: product.on_sale,
                        sale_price: product.sale_price,
                        slug: product.slug
                    }
                    setSearchResults(prevList => [...prevList, productDetails]);
                });
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    }
    
    useEffect(() => {
        // Fetch responses when search term changes
        if (searchTerm && !fetched.current) {
            fetchResponses(searchTerm);
            fetched.current = true;
        }
    }, [searchTerm]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSearch = () => {
        if (search && search.length > 2) {
            setCurrentSearchTerm(search);
            navigate(`/search/${search}`);
            fetched.current = false; // Reset fetched to allow fetching new results
        }
    }

    return (
        <div className='min-h-screen bg-main'>
            <div className='max-w-desktop m-auto'>
                <div className="w-full flex items-center justify-center py-12 flex-col gap-4">
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="border-b border-neutral-500 focus:outline-none text-center w-1/2 min-w-[240px]" />
                    <div className="rounded-sm border border-neutral-500 px-4 py-1 flex items-center gap-1 cursor-pointer select-none" onClick={handleSearch}>
                        <IonIcon icon='search' /> Search
                    </div>
                </div>

                <div className='w-full grid grid-cols-4 gap-6'>
                    {searchResults.map((product, index) => (
                        <ProductListItem key={index} {...product} />
                    ))}

                    {searchResults.length === 0 && (
                        <div className='col-span-4 text-center text-lg text-neutral-500 h-full py-12'>
                            No results found for '{currentSearchTerm}'
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Search;
