import { useEffect } from "react";
import TermsAndConditions from "../../../Components/Misc/TermsAndConditions/TermsAndConditions";


const TermsAndConditionsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="pt-[55px]">
            <TermsAndConditions />
        </div>
    )
}

export default TermsAndConditionsPage;