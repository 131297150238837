const LOCAL_API_URL = 'http://127.0.0.1:8000'
const LOCAL_MEDIA_URL = 'http://127.0.0.1:8000';
const LOCAL_REDIRECT_URL = 'http://127.0.0.1:8000/api/orders'
const PRODUCTION_API_URL = 'https://www.moncboutique.gr'
const DEPLOYMENT_MEDIA_URL = 'https://www.moncboutique.gr';
const DEPLOYMENT_REDIRECT_URL = 'https://www.moncboutique.gr/api/orders'

export const ACTIVE_URL = PRODUCTION_API_URL;
export const ACTIVE_MEDIA_URL = DEPLOYMENT_MEDIA_URL;
export const ACTIVE_REDIRECT_URL = DEPLOYMENT_REDIRECT_URL;
