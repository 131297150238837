

const TermsAndConditions = () => {
    return (
        <div className="min-h-screen max-w-desktop m-auto py-12">
            <h3 className="text-3xl mb-10">
                Terms and Conditions
            </h3>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    General
                </h4>
                <div>
                    Welcome to our online store. By using our website, you automatically agree to the following terms, which you should read carefully. These terms of use only apply to transactions performed exclusively through the company’s moncboutique.gr website with the following features:
                </div>
                <div>
                    Company name: OLGA KIOURKENIDI
                </div>
                <div>
                    Headquarters: Kyprou 11, Metamorphosi, Athens
                </div>
                <div>
                    Any software available for downloading by this server is the intellectual property of moncboutique.gr. The use of such documents by this server is restricted solely for informational and non-commercial purposes or is only personal and will not be copied, nor will it be placed on another computer network, nor will it be distributed by other means of communication. There is absolutely no intervention in the documents. Any other use is strictly prohibited by law and may involve severe civil and criminal penalties. Violators will be prosecuted.
                </div>
                <div>
                    moncboutique.gr does not state in any way that the information contained in the documents and announcements, published on this server, is suitable for any purpose. Under any circumstances, including the case of negligence, we are not responsible for any form of damage suffered by the services, options and contents of moncboutique.gr which you carry out on your own initiative. The contents of moncboutique.gr are provided “as is” without any warranty expressed or implied in any way. To the fullest extent and in accordance with the law, moncboutique.gr denies all guarantees expressed or implied, including, but not limited to, those that imply marketability and suitability for a particular purpose. moncboutique.gr does not guarantee that the pages, services, options and contents will be provided without interruption, without errors and that errors will be corrected. It also does not guarantee that this or any other related website or servers through which they are made available to you will not contain “viruses” or other harmful components. moncboutique.gr does not guarantee in any case the correctness, completeness or even availability of the content pages, services, options or their results. The cost of any corrections or services is borne by you and in no case moncboutique.gr. You are free to use this website in accordance with the Law and good morals. The responsibility for the contents of the transactions lies solely with you. moncboutique.gr does not make any kind of correction or intervention in the data you transfer. We ask that you fill in the following fields (name, address, message) correctly on the on-line contact forms. The use of the online store is subject to Greek and International laws and you agree not to use our site in order to violate these laws.
                </div>
                <div>
                    By using moncboutique.gr you agree and undertake not to use the online store for sending, publishing, sending by e-mail or transmission in other ways of any content that is illegal for any reason, causing illegal infringement and damage to moncboutique.gr or in any third party, infringes the confidentiality or confidentiality of information of any person, violates the provisions on personal data protection, infringes good morals, infringes the rights of intellectual or industrial property, is secret or confidential or contrary to private contracts or legal provisions. You must also not transmit any form of material that contains software viruses or any other code, files or programs designed to interrupt and cause damage, damage or equipment to the operation of any software or computer hardware. Also with the use of moncboutique.gr you accept and agree that all members, associates, employees, management, shareholders, agents and other associates of moncboutique.gr are not responsible for any damage resulting from third parties using moncboutique.gr. Any use contrary to the above, in addition to criminal or civil penalties, results in the termination of the services provided without any notice.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Liks to other sites
                </h4>
                <div>
                    moncboutique.gr includes links to other websites which are not controlled by the same but by their third parties (natural or legal persons). moncboutique.gr is not responsible for the content of other websites and is not responsible for any damage or injury that may arise from the content of these websites. moncboutique.gr disclaims any responsibility regarding the use of the linked sites, which are used at the risk of the user. In no case is moncboutique.gr responsible for the terms of protection of the personal data of the visitors/users which these bodies follow. moncboutique.gr is in no way responsible for or may consider that it embraces or accepts the content or services of the websites to which it refers or that it is linked to them in any other way. Any third party wishing to install links to this website must first contact moncboutique.gr for this purpose, which reserves the right to refuse permission for such links to its website. However, if moncboutique.gr grants its permission for such connections, it is not obliged to make a compensatory connection.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Advertising
                </h4>
                <div>
                    moncboutique.gr has no responsibility for the communication of the user with the third party service providers that are advertised in its online store and for any commercial transaction that may arise from the relationship between them.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Intellectual and industrial property rights-trademarks
                </h4>
                <div>
                    Apart from the explicitly mentioned exceptions (copyright of third parties), all the content of moncboutique.gr including images, graphics, photos, drawings, texts, services provided and in general all files of this website, constitute copyright, trademarks and trademarks of moncboutique.gr and are protected by the relevant provisions of Greek law, European law and international conventions and treaties. Therefore, none of them can in whole or in part be sold, copied, modified, reproduced, republished or “loaded”, transmitted or distributed in any digital or non-digital way. The other products or services mentioned on the websites of this node and bearing the trademarks of the respective organizations, companies, partner organizations, associations or publications, constitute their own intellectual and industrial property and therefore these bodies bear the relevant responsibility. The users of moncboutique.gr accept that you are not provided with the right to reproduce, copy, sell, resell and/or commercially exploit in any way all or part of the content of moncboutique.gr. The appearance of the above property rights of moncboutique.gr on the website should in no way be construed as a transfer or assignment of their license or right of use.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Valid Law
                </h4>
                <div>
                    The site has been created and is controlled by OLGA KIOURKENIDI with headquarters: Kyprou 11, Metamorphosi, Athens and the Greek Legislation will guide the use of this website and its interpretation. If you choose to access this site from another country, you are responsible for complying with the laws of that country. The above terms constitute the full agreement with sekoia.gr which has the right to make changes to the content of the website and the above terms without any notice.
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;