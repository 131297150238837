import HomeActiveSeason from "../../Components/Home/HomeActiveSeason/HomeActiveSeason";
import HomeFeatured from "../../Components/Home/HomeFeatured/HomeFeatured";
import HomeHeader from "../../Components/Home/HomeHeader/HomeHeader";


const HomePage = () => {

    return (
        <div className="pt-[55px]">
            <HomeHeader />
            <HomeFeatured />
            <HomeActiveSeason />
        </div>
    )

}

export default HomePage;