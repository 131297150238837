

const Returns = () => {

    return (
        <div className="min-h-screen max-w-desktop m-auto py-12">
            <h3 className="text-3xl mb-10">
                Returns
            </h3>
            <div className="flex flex-col gap-2 mb-6">
                <div>
                    If you are not happy with the goods you have received, you can return them within 14 days from the day you receive your order, providing they are in original resalable condition and the receipt is included in the returned package.
                </div>
                <div>
                    We are not able to exchange items that appear to have been worn, washed, or are not in the original condition.
                </div>
                <div>
                    All returned packages must be sent with a trackable, insured service, as we cannot take responsibility for damaged or lost items in the return transit.
                </div>
                <div>
                    Swimwear can only be accepted as a return with the safe tape and brand tag included.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Returns in Greece:
                </h4>
                <div>
                    For all items shipped in Greece, we offer a return service using the ACS courier service. The steps are simple:
                </div>
                <ul>
                    <li>
                        Return the initial parcel using one of our couriers. In your package along with the receipt enclose the filled-in return form (which we e-mailed you along with the receipt of your purchace) where you note if you wish to proceed with credit voucher or product exchange.
                    </li>
                    <li>
                        As soon as we receive your returned parcel, we proceed with the option you have selected (* You could contact us via email or phone to ensure that the new product is available).
                    </li>
                    <li>
                        If you choose the credit voucher you should know that it has an expiration date of 60 days after the date of your order.
                    </li>
                    <li>
                        If you choose to proceed with a product exchange, you need to note it in the return form and make a new order. The new order will be sent following our standard shipping conditions.
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    International returns including Cyprus:
                </h4>
                <div>
                    Not yet available.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Important Notice:
                </h4>
                <div>
                    Monetary refunds only apply when the product is defective or wrong.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Store Address:
                </h4>
                <div>
                    Kyprou 11, Metamorfosi, Attiki
                </div>
            </div>

        </div>
    )
}

export default Returns;