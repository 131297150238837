import React from 'react';
import { ProductItemGridDetails } from '../../../../interfaces';
import { ACTIVE_MEDIA_URL } from '../../../../constants';

interface HomeFeaturedItemProps extends ProductItemGridDetails {
    isItemHovered: boolean;
}

const HomeFeaturedItem: React.FC<HomeFeaturedItemProps> = ({ title, image, colors, sizes, price, isItemHovered }) => {
    return (
        <div className='pointer-events-none w-full flex flex-col gap-1 cursor-pointer select-none z-0'>
            <div className='mb-2'>
                <img src={ACTIVE_MEDIA_URL + image} alt={title + ' image'} className='rounded-sm shadow-md select-none' />
            </div>
            <div className='flex flex-col items-center'>
                <h3 className='text-lg'>{title}</h3>
                <p className='text-sm'>&euro; {price}</p>
            </div>
            <div className={`${isItemHovered ? 'opacity-1' : 'opacity-0'} duration-150 flex items-center justify-center gap-2 text-sm`}>
                <div>
                    Available sizes:
                </div>
                <div>
                    {sizes.join(' ')}
                </div>
                <div className='flex items-center'>
                    {colors.map((color, index) => (
                        <span key={index} className='w-3.5 h-3.5 rounded-full inline-block border' style={{ backgroundColor: color }}></span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HomeFeaturedItem;
