import { useEffect } from "react";
import PrivacyPolicy from "../../../Components/Misc/PrivacyPolicy/PrivacyPolicy";


const PrivacyPolicyPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="pt-[55px]">
            <PrivacyPolicy />
        </div>
    )
}

export default PrivacyPolicyPage;