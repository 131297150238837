import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTIVE_URL } from '../../../constants';
import { FeaturedCategory } from '../../../interfaces';
import { motion } from 'framer-motion';
import ProductListItem from '../../ProductList/ProductListItem/ProductListItem';
import HomeFeaturedItem from './HomeFeaturedItem/HomeFeaturedItem';

const HomeFeatured = () => {
    const [productCategories, setProductCategories] = useState<FeaturedCategory[]>([]);
    const [activeCategory, setActiveCategory] = useState<FeaturedCategory | null>(null);
    const [categoryNames, setCategoryNames] = useState<string[]>([]);
    const fetched = useRef<boolean>(false);
    const [carouselWidth, setCarouselWidth] = useState<number>(0);
    const carousel = useRef<HTMLDivElement>(null);
    const [dragStartX, setDragStartX] = useState<number>(0);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/products/get_featured_categories/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    const tempCategories: FeaturedCategory[] = data.map((category: any) => ({
                        name: category.name,
                        products: category.featured_products.map((product: any) => ({
                            title: product.title,
                            image: product.image,
                            brand_name: product.brand_name,
                            category_name: product.category_name,
                            colors: product.colors.map((color: { code: string }) => color.code),
                            sizes: product.sizes.map((size: { code: string }) => size.code),
                            price: product.price,
                            on_sale: product.on_sale,
                            sale_price: product.sale_price,
                            slug: product.slug
                        }))
                    }));
                    setProductCategories(tempCategories);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    useEffect(() => {
        if (productCategories.length > 0) {
            setActiveCategory(productCategories[0]);
            setCategoryNames(productCategories.map(category => category.name));
        }
    }, [productCategories]);

    useEffect(() => {
        if (carousel.current) {
            setCarouselWidth(carousel.current.scrollWidth - carousel.current.clientWidth);
        }
    }, [activeCategory]);

    const handleMouseDown = (event: React.MouseEvent) => {
        setDragStartX(event.clientX);
    };

    const handleClick = (event: React.MouseEvent, slug: string) => {
        if (Math.abs(event.clientX - dragStartX) < 25) {
            navigate(`/item/${slug}`);
        }
    };

    return (
        <div className='min-h-[60vh] bg-main py-12 select-none'>
            <div className='max-w-desktop m-auto'>
                <div className='flex flex-col gap-5'>
                    <h4 className='text-3xl'>Featured</h4>
                    <div className=' flex gap-3'>
                        {categoryNames.length > 0 && categoryNames.map((category, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveCategory(productCategories[index])}
                                className={`text-xl cursor-pointer leading-[1.4rem] border-b  ${activeCategory?.name === category ? 'border-neutral-700 text-neutral-700' : 'border-neutral-400 text-neutral-400'}`}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                    <div className='relative z-0'>
                        <motion.div
                            ref={carousel}
                            className='carousel overflow-hidden cursor-grab z-0'
                        >
                            <motion.div
                                drag='x'
                                dragConstraints={{ right: 0, left: -carouselWidth }}
                                className='inner-carousel flex z-0'
                            >
                                {productCategories.length > 0 && activeCategory?.products && activeCategory.products.map((product, index) => (
                                    <motion.div
                                        key={index}
                                        className='h-full min-w-[230px] w-[230px] sm:min-w-[390px] sm:w-[390px] pr-8'
                                        onMouseDown={handleMouseDown}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <motion.div onClick={(event) => handleClick(event, product.slug)}>
                                            <HomeFeaturedItem {...product} isItemHovered={hoveredIndex === index} />
                                        </motion.div>

                                    </motion.div>
                                ))}

                                {productCategories.length === 0 && <div className='text-lg'></div>}
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeFeatured;
