

const Shipping = () => {

    return (
        <div className="min-h-screen max-w-desktop m-auto py-12">
            <h3 className="text-3xl mb-10">
                Shipping
            </h3>
            <div className="flex flex-col gap-2 mb-6">
                <div>
                    Your parcel is ready to leave our premises within the next working day of your order. Exceptions apply only to some of our products like belts or bags.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Shipping in Greece:
                </h4>
                <div>
                    All deliveries are being handled by ACS Courier. The delivery timings vary depending on the area you live in:
                </div>
                <ul>
                    <li>
                        1-3 working days within Attica
                    </li>
                    <li>
                        1-4 working days for the rest of Greece
                    </li>
                    <li>
                        1-6 working days for remote area
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    Shipping in Cyprus:
                </h4>
                <div>
                    Not yet available.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    International Shipping:
                </h4>
                <div>
                    Not yet available.
                </div>
            </div>
        </div>
    )
}

export default Shipping;