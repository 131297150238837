import React from 'react';
import { ProductItemGridDetails } from '../../../interfaces';
import { ACTIVE_MEDIA_URL } from '../../../constants';
import { Link } from 'react-router-dom';

const ProductListItem: React.FC<ProductItemGridDetails> = ({ title, image, brand_name, category_name, colors, sizes, price, on_sale, sale_price, slug }) => {
    const [isHovered, setIsHovered] = React.useState<boolean>(false);

    return (
        <Link to={'/item/' + slug} className='w-full flex flex-col gap-1 cursor-pointer select-none z-0' onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className='mb-2 '>
                <img src={ACTIVE_MEDIA_URL + image} alt={title + ' image'} className='rounded-sm shadow-md select-none ' />
            </div>
            <div className='flex flex-col items-center'>
                <h3 className='text-lg'>{title}</h3>
                <p className='text-sm'>&euro; {price}</p>
            </div>
            <div className={`${isHovered ? 'opacity-1' : 'opacity-0'} duration-150 flex items-center justify-center gap-2 text-sm`}>
                <div>
                    Available sizes:
                </div>
                <div>
                    {sizes.join(' ')}
                </div>
                <div className='flex items-center'>
                    {colors.map((color, index) => {
                        return <span key={index} className='w-3.5 h-3.5 rounded-full inline-block border' style={{ backgroundColor: color }}></span>

                    })}
                </div>
            </div>
        </Link>
    )
}

export default ProductListItem;