import { IonIcon } from "@ionic/react";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../../../assets/images/logos/logo.png';
import { BagContext } from '../../../utils/CartContext/CartContext';
import { ACTIVE_MEDIA_URL } from "../../../constants";
import { useNavigate } from 'react-router-dom';
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "../../../shadcn/components/ui/drawer"
import { Button } from "../../../shadcn/components/ui/button";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Separator } from "../../../shadcn/components/ui/separator";
import { Sling as Hamburger } from 'hamburger-react';

const Navbar = () => {
    const [isSearchHovered, setIsSearchHovered] = useState(false);
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
    const { numItems, bagItems, addedItem, increaseQuantity, reduceQuantity, removeItem, bagInfo } = useContext(BagContext) || { numItems: 0 };
    const [bagOpen, setBagOpen] = useState(false);
    const [bagIsAlwaysClosed, setBagIsAlwaysClosed] = useState(false);
    const initialLoad = useRef(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (addedItem && addedItem > 0 && bagItems && bagItems.length > 0) {
            setBagOpen(true);
        }
    }, [addedItem]);

    const toggleSearchBarOpen = () => {
        setIsSearchBarOpen(!isSearchBarOpen);
    }

    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [isFixed, setIsFixed] = useState(false);
    const [isOnTop, setIsOnTop] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY === 0) {
                setIsFixed(false);
                setIsOnTop(true);
            } else if (lastScrollY > currentScrollY) {
                setIsFixed(true);
                setIsOnTop(false);
            } else if (lastScrollY < currentScrollY && isFixed) {
                setIsFixed(false);
                setIsOnTop(true);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    // if current page is bag, close the bag
    useEffect(() => {
        setIsSearchBarOpen(false);
        setBagOpen(false);
        setBagIsAlwaysClosed(location.pathname === '/bag');
    }, [location]);

    useEffect(() => {
        if (bagOpen && isSearchBarOpen) {
            setIsSearchBarOpen(false);
        }
    }, [bagOpen]);

    useEffect(() => {
        if (bagOpen && isSearchBarOpen) {
            setBagOpen(false);
        }
    }, [isSearchBarOpen]);

    const handleSearch = () => {
        if (searchTerm && searchTerm.length > 2) {
            navigate(`/search/${searchTerm}`);
        }
    }

    if (location.pathname === '/checkout') return (

        <div className={`bg-main relative z-50`}>
            <div className={`bg-main duration-100 w-full ${isFixed ? 'fixed bg-main shadow-lg top-0 animate-nav-slide-in' : (isOnTop ? 'absolute' : 'fixed top-[-100%] bg-main animate-nav-slide-out shadow-lg')} `}>
                <div className="select-none z-10 max-w-desktop m-auto ">
                    <div className={`h-[55px] bg-main text-neutral-800 select-none  `}>
                        <div className="h-full text-lg  grid grid-cols-navbar items-center justify-center">
                            <div className="flex justify-start items-center gap-4 h-full">

                            </div>
                            <div className="flex h-full items-center justify-center">
                                <Link to="/"><img src={logo} alt="Logo" className="max-h-8" /></Link>

                            </div>
                            <div className="justify-end flex gap-4">

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`fixed right-0 mr-8 duration-500 select-none top-0 w-[320px] h-screen pt-[35px] pb-[30px] gap-8 hidden lg:block`}>
                <div className=" px-4 shadow-xl border flex bg-beige flex-col justify-between w-full  py-5 h-full rounded-md">
                    <div className="text-2xl pt-4 pb-8">Bag Overview</div>
                    <div className="border-t border-b border-neutral-300 py-2 flex flex-col gap-2 text-sm">
                        <div className="w-full flex justify-between items-center px-0.5">
                            <div>
                                Subtotal
                            </div>
                            <div>
                                &euro; {bagInfo ? bagInfo.subtotal : 0}
                            </div>
                        </div>
                        <div className="w-full flex justify-between items-center px-0.5">
                            <div>
                                Express (3-5 days)
                            </div>
                            <div>
                                &euro; {bagInfo ? bagInfo.shipping : 0}
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-neutral-300 flex flex-col gap-2 text-sm py-2 mb-4">
                        <div className="w-full flex justify-between items-center px-0.5">
                            <div>
                                Total
                            </div>
                            <div>
                                &euro; {bagInfo ? bagInfo.total : 0}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 h-full overflow-y-scroll ">
                        {bagItems && bagItems.map((item, index) => (
                            <div key={index} className="grid grid-cols-2 gap-2">
                                <img src={ACTIVE_MEDIA_URL + item.image_url} alt={item.name} className="rounded-sm" />
                                <div className="flex flex-col justify-between">
                                    <div className="text-lg">{item.name}</div>
                                    <div className="flex-col flex gap-0.5">
                                        <div>Size: {item.size}</div>
                                        <div>Color: {item.color} </div>
                                        <div className='flex gap-1 items-center justify-start'>
                                            <div>
                                                Quantity:
                                            </div>
                                            {item.quantity}
                                        </div>
                                        <div>Price: &euro;{item.price}</div>
                                    </div>
                                </div>

                            </div>
                        ))}
                        {bagItems && bagItems.length === 0 ? <div className="text-center h-full flex items-center justify-center">Your bag is empty</div> : null}
                    </div>

                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className={`bg-main hidden md:block relative z-50`}>

                {/* Bag */}
                <div className={`fixed ${bagOpen && !bagIsAlwaysClosed ? 'right-0 mr-8' : 'right-[-320px] '} duration-500 select-none top-0 w-[320px] h-screen  pt-[75px] pb-[30px] gap-8 `}>
                    <div className=" px-4 shadow-xl border flex bg-beige flex-col justify-between w-full  py-5 h-full rounded-md">
                        <div className="text-2xl pt-4 pb-8">Bag Overview</div>
                        <div className="border-t border-b border-neutral-300 py-2 flex flex-col gap-2 text-sm">
                            <div className="w-full flex justify-between items-center px-0.5">
                                <div>
                                    Subtotal
                                </div>
                                <div>
                                    &euro; {bagInfo ? bagInfo.subtotal : 0}
                                </div>
                            </div>
                            <div className="w-full flex justify-between items-center px-0.5">
                                <div>
                                    Express (3-5 days)
                                </div>
                                <div>
                                    &euro; {bagInfo ? bagInfo.shipping : 0}
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-neutral-300 flex flex-col gap-2 text-sm py-2 mb-4">
                            <div className="w-full flex justify-between items-center px-0.5">
                                <div>
                                    Total
                                </div>
                                <div>
                                    &euro; {bagInfo ? bagInfo.total : 0}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 h-full overflow-y-scroll ">
                            {bagItems && bagItems.map((item, index) => (
                                <div key={index} className="grid grid-cols-2 gap-2">
                                    <img src={ACTIVE_MEDIA_URL + item.image_url} alt={item.name} className="rounded-sm" />
                                    <div className="flex flex-col justify-between">
                                        <div className="text-lg">{item.name}</div>
                                        <div className="flex-col flex gap-0.5">
                                            <div>Size: {item.size}</div>
                                            <div>Color: {item.color} </div>

                                            <div className='flex gap-1 items-center justify-start'>
                                                <div>
                                                    Quantity:
                                                </div>

                                                <div
                                                    className='h-3 w-3 rounded-full border border-neutral-800 text-black   flex items-center justify-center cursor-pointer'
                                                    onClick={() => increaseQuantity && increaseQuantity(item, 1)}
                                                >
                                                    <IonIcon icon="add" className="text-black" />
                                                </div>
                                                {item.quantity}
                                                <div
                                                    className={`h-3 w-3 rounded-full ${item.quantity === 1 ? 'text-neutral-400 border-neutral-400' : 'text-neutral-800 border-neutral-800'} border  flex items-center justify-center cursor-pointer`}
                                                    onClick={() => item.quantity > 1 && reduceQuantity && reduceQuantity(item, 1)}
                                                >
                                                    <IonIcon icon="remove" />
                                                </div>


                                            </div>
                                            <div>Price: &euro;{item.price}</div>
                                        </div>
                                        <div
                                            className="text-base text-red-500 cursor-pointer underline"
                                            onClick={() => removeItem && removeItem(item)}
                                        >
                                            Remove Item
                                        </div>
                                    </div>

                                </div>
                            ))}
                            {bagItems && bagItems.length === 0 ? <div className="text-center h-full flex items-center justify-center">Your bag is empty</div> : null}
                        </div>
                        <div className="w-full flex flex-col gap-2 mt-4">
                            <div className="border w-full py-2 text-center border-neutral-800 rounded-sm items-center justify-center flex gap-1.5 cursor-pointer" onClick={() => setBagOpen(false)}>
                                <IonIcon icon="caret-forward" className="text-lg mb-0.5" />
                                Continue Shopping
                            </div>
                            {
                                numItems === 0 ? null :
                                    <Link to='/bag' className="w-full py-2 bg-neutral-800 text-white rounded-sm items-center justify-center flex gap-2" onClick={() => setBagOpen(false)}>
                                        <IonIcon icon="bag-handle" className="text-lg mb-1" />
                                        View Bag
                                    </Link>
                            }

                        </div>
                    </div>
                </div>


                <div className={`bg-main  duration-100 w-full ${isFixed ? 'fixed bg-main shadow-lg top-0 animate-nav-slide-in' : (isOnTop ? 'absolute' : 'fixed top-[-100%] bg-main animate-nav-slide-out shadow-lg')} `}>
                    <div className="select-none z-10 max-w-desktop m-auto ">
                        <div className={`h-[55px] bg-main text-neutral-800 select-none  `}>
                            <div className="h-full text-lg  grid grid-cols-3 items-center justify-center">
                                <div className="flex justify-start items-center gap-4 h-full">
                                    <Link to='/' className="hover:underline">
                                        Home
                                    </Link>
                                    <Link to='/woman' className="hover:underline">
                                        Woman
                                    </Link>
                                    <div className="gap-2 flex items-center justify-center cursor-pointer" onMouseOver={() => setIsSearchHovered(true)} onMouseLeave={() => setIsSearchHovered(false)} onClick={toggleSearchBarOpen}>
                                        <IonIcon icon="search" className="text-neutral-800 " />
                                        <div className={`underline duration-100 ${isSearchHovered ? 'opacity-1' : 'opacity-0'}`}>
                                            Search
                                        </div>
                                    </div>
                                </div>
                                <div className="flex h-full items-center justify-center">
                                    <Link to="/"><img src={logo} alt="Logo" className="max-h-8" /></Link>
                                </div>
                                <div className="justify-end flex gap-4">
                                    {/* <Link to='/' className="hover:underline">
                                    Account
                                </Link> */}
                                    <div onClick={() => setBagOpen(!bagOpen)} className="flex items-center gap-1 hover:underline cursor-pointer">
                                        <IonIcon icon="bag-outline" />
                                        <div>Bag ({numItems})</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`flex h-[55px] absolute max-w-desktop w-full px-4 gap-4 items-center bg-main  ${isSearchBarOpen ? 'max-h-[55px] border rounded-sm border-neutral-800 z-10' : 'max-h-0 '} duration-200`}>
                            <div className={`${isSearchBarOpen ? 'opacity-1 ' : 'opacity-0 '} duration-100`}>
                                <div className="h-7 w-7 rounded-sm animate-search-blink">
                                </div>
                            </div>
                            <div className="w-full">
                                <input type="text" placeholder="Search for brand, garment type or color" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className={`${isSearchBarOpen ? 'opacity-1 ' : 'opacity-0 hidden'} duration-50 w-full border-b border-b-neutral-800 bg-main focus:outline-none h-7`} />
                            </div>
                            <div className={`${isSearchBarOpen ? 'opacity-1 ' : 'opacity-0 '} duration-50 px-5 h-7 flex items-center justify-center py-1 border border-neutral-800 text-sm cursor-pointer rounded-sm`} onClick={handleSearch}>
                                SEARCH
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`bg-main block  md:hidden relative z-50 select-none`}>
                <div className={`bg-main   w-full ${isFixed ? 'fixed bg-main shadow-lg top-0 animate-nav-slide-in' : (isOnTop ? 'absolute' : 'fixed top-[-100%] bg-main animate-nav-slide-out shadow-lg')} duration-200`}>
                    <div className="select-none z-10 max-w-desktop m-auto ">
                        <div className={`h-[55px] bg-main text-neutral-800 select-none  `}>
                            <div className="h-full text-lg  grid grid-cols-3 items-center justify-center">
                                <div className="flex justify-start items-center gap-4 h-full">

                                    <Drawer open={isMenuOpen && !isDesktop} onOpenChange={setIsMenuOpen} >
                                        <DrawerTrigger>
                                            <div className="cursor-pointer ml-[-10px] scale-[60%] rounded-full">
                                                <Hamburger easing="ease-in" color='black' label="Show menu" toggled={isMenuOpen} />
                                            </div>
                                        </DrawerTrigger>
                                        <DrawerContent >
                                            <DrawerHeader className="text-left text-center pt-6 pb-4">
                                                <DrawerTitle>Menu</DrawerTitle>
                                                <DrawerDescription>

                                                </DrawerDescription>
                                            </DrawerHeader>
                                            <div className="h-full flex flex-col items-center justify-center text-lg gap-4 py-8">
                                                <Link to='/' className="underline" onClick={() => setIsMenuOpen(false)}>Home</Link>
                                                <Link to='/woman' className="underline" onClick={() => setIsMenuOpen(false)}>Woman</Link>
                                                <div className="underline" onClick={() => { setIsMenuOpen(false); setIsSearchBarOpen(true); }}>Search</div>
                                                <div className="underline" onClick={() => { setIsMenuOpen(false); setBagOpen(true); }}>View Bag</div>
                                            </div>
                                            <DrawerFooter>
                                                <DrawerClose>
                                                    <Button variant="outline" className="border w-full py-2 text-center border-neutral-800 rounded-sm items-center justify-center flex gap-1.5 cursor-pointer bg-white text-lg">
                                                        <IonIcon icon="caret-forward" className="text-lg mb-0.5" />
                                                        Continue Shopping
                                                    </Button>
                                                </DrawerClose>
                                            </DrawerFooter>
                                        </DrawerContent>
                                    </Drawer>
                                </div>
                                <Link to='/' className="flex h-full items-center justify-center">
                                <Link to="/"><img src={logo} alt="Logo" className="max-h-8"/></Link>
                                </Link>
                                <div className="justify-end flex gap-2.5 block">

                                    <Drawer open={isSearchBarOpen && !isDesktop} onOpenChange={setIsSearchBarOpen} >
                                        <DrawerTrigger className="flex gap-1 items-center justify-center relative">
                                            <IonIcon icon="search-outline" className="text-2xl mt-0.5" />

                                        </DrawerTrigger>
                                        <DrawerContent className="h-[85vh]">
                                            <DrawerHeader className="text-left text-center pt-6 pb-4">
                                                <DrawerTitle>Search</DrawerTitle>
                                                <DrawerDescription>

                                                </DrawerDescription>
                                            </DrawerHeader>
                                            <div className="px-4 flex flex-col items-center justify-center h-full">
                                                <div className="w-full">
                                                    <input type="text" placeholder="Search for brand, garment type or color" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className={`${isSearchBarOpen ? 'opacity-1 ' : 'opacity-0 hidden'} duration-50 w-full border-b border-b-neutral-800 bg-main focus:outline-none h-7 text-center`} />
                                                </div>
                                            </div>
                                            <DrawerFooter>
                                                <div className="w-full flex flex-col gap-2">
                                                    <DrawerClose>
                                                        <Button variant="outline" className="border w-full py-2 text-center border-neutral-800 rounded-sm items-center justify-center flex gap-1.5 cursor-pointer bg-white text-lg">
                                                            <IonIcon icon="caret-forward" className="text-lg mb-0.5" />
                                                            Continue Shopping
                                                        </Button>
                                                    </DrawerClose>
                                                </div>
                                                <div className={`${isSearchBarOpen ? 'opacity-1 ' : 'opacity-0 '} duration-50 px-5 py-2 flex items-center justify-center py-1 border border-neutral-800 text-lg cursor-pointer w-full py-2 bg-neutral-800 text-white rounded-sm items-center justify-center flex gap-2`} onClick={handleSearch}>
                                                    Search
                                                </div>
                                            </DrawerFooter>
                                        </DrawerContent>
                                    </Drawer>


                                    <Drawer open={bagOpen && !isDesktop} onOpenChange={setBagOpen} >
                                        <DrawerTrigger className="flex gap-1 items-center justify-center relative">
                                            <IonIcon icon="bag-outline" className="text-2xl" />
                                            {numItems > 0 ? (
                                                <div className="absolute bottom-[-6px] text-xs bg-neutral-800 w-4 h-4 rounded-full text-main right-[-4px] flex items-center justify-center">
                                                    <div className="mt-0.5">
                                                        {numItems}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </DrawerTrigger>
                                        <DrawerContent className="h-[85dvh] w-full px-4">
                                            <DrawerHeader className="text-left text-center pt-6 pb-4">
                                                <DrawerTitle>Bag Overview</DrawerTitle>
                                                <DrawerDescription>

                                                </DrawerDescription>
                                            </DrawerHeader>

                                            {bagItems && bagItems.length !== 0 ? (
                                                <>
                                                    <div className="border-t border-b border-neutral-300 py-2 flex flex-col gap-2 text-sm">
                                                        <div className="w-full flex justify-between items-center px-0.5">
                                                            <div>Subtotal</div>
                                                            <div>&euro; {bagInfo ? bagInfo.subtotal : 0}</div>
                                                        </div>
                                                        <div className="w-full flex justify-between items-center px-0.5">
                                                            <div>Express (3-5 days)</div>
                                                            <div>&euro; {bagInfo ? bagInfo.shipping : 0}</div>
                                                        </div>
                                                    </div>
                                                    <div className="border-b border-neutral-300 flex flex-col gap-2 text-sm py-2 mb-4">
                                                        <div className="w-full flex justify-between items-center px-0.5">
                                                            <div>Total</div>
                                                            <div>&euro; {bagInfo ? bagInfo.total : 0}</div>
                                                        </div>
                                                    </div>
                                                    <ScrollArea className="overflow-auto py-4 break-all">

                                                        {bagItems && bagItems.map((item, index) => (
                                                            <div key={index} className="grid grid-cols-2 gap-2 mb-2">
                                                                <img src={ACTIVE_MEDIA_URL + item.image_url} alt={item.name} className="rounded-sm" />
                                                                <div className="flex flex-col justify-between">
                                                                    <div className="text-lg">{item.name}</div>
                                                                    <div className="flex-col flex gap-0.5">
                                                                        <div>Size: {item.size}</div>
                                                                        <div>Color: {item.color}</div>
                                                                        <div className='flex gap-1 items-center justify-start'>
                                                                            <div>Quantity:</div>
                                                                            <div className='h-4 w-4 rounded-full border border-neutral-800 text-black flex items-center justify-center cursor-pointer' onClick={() => increaseQuantity && increaseQuantity(item, 1)}>
                                                                                <IonIcon icon="add" className="text-black text-xs" />
                                                                            </div>
                                                                            {item.quantity}
                                                                            <div className={`h-4 w-4 rounded-full ${item.quantity === 1 ? 'text-neutral-400 border-neutral-400' : 'text-neutral-800 border-neutral-800'} border flex items-center justify-center cursor-pointer`} onClick={() => item.quantity > 1 && reduceQuantity && reduceQuantity(item, 1)}>
                                                                                <IonIcon icon="remove" className="text-xs" />
                                                                            </div>
                                                                        </div>
                                                                        <div>Price: &euro;{item.price}</div>
                                                                    </div>
                                                                    <div className="text-base text-red-500 cursor-pointer underline" onClick={() => removeItem && removeItem(item)}>
                                                                        Remove Item
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </ScrollArea>
                                                </>
                                            ) : (
                                                <div className="text-center h-full flex items-center justify-center">Your bag is empty</div>
                                            )}

                                            <DrawerFooter className="pt-2 px-0">
                                                <div className="w-full flex flex-col gap-2">
                                                    <DrawerClose className="border w-full py-2 text-center border-neutral-800 rounded-sm items-center justify-center flex gap-1.5 cursor-pointer bg-white" onClick={() => setBagOpen(false)}>
                                                        <IonIcon icon="caret-forward" className="text-lg mb-0.5" />
                                                        Continue Shopping
                                                    </DrawerClose>
                                                    {numItems === 0 ? null : (
                                                        <Link to='/bag' className="w-full py-2 bg-neutral-800 text-white rounded-sm items-center justify-center flex gap-2" onClick={() => setBagOpen(false)}>
                                                            <IonIcon icon="bag-handle" className="text-lg mb-1" />
                                                            View Bag
                                                        </Link>
                                                    )}
                                                </div>
                                            </DrawerFooter>
                                        </DrawerContent>
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar;