import { useEffect } from "react";
import Shipping from "../../../Components/Misc/Shipping/Shipping";


const ShippingPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="pt-[55px]">
            <Shipping />
        </div>
    )
}

export default ShippingPage;

