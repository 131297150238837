import ProductListGrid from "../../Components/ProductList/ProductListGrid/ProductListGrid";


const ProductListPage = () => {

    return (
        <div className="pt-[55px]">
            <ProductListGrid />
        </div>
    )
}

export default ProductListPage;