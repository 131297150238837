import { OrderStatus } from "../../../interfaces";

const StatusLines: React.FC<{ status: OrderStatus }> = ({ status }) => {
    const getStatusLines = (status: OrderStatus) => {
        const lines = ['empty', 'empty', 'empty', 'empty', 'empty'];
        switch (status) {
            case 'Accepted':
                lines[0] = 'green';
                break;
            case 'Preparing Order':
                lines[0] = 'green';
                lines[1] = 'green';
                break;
            case 'Shipping':
                lines[0] = 'green';
                lines[1] = 'green';
                lines[2] = 'green';
                break;
            case 'Completed':
                lines[0] = 'green';
                lines[1] = 'green';
                lines[2] = 'green';
                lines[3] = 'green';
                lines[4] = 'green';
                break;
            case 'Canceled':
                lines.fill('red');
                break;
        }
        return lines;
    }

    return (
        <div className="grid grid-cols-5 gap-2 w-full max-w-md mb-15">
            {getStatusLines(status).map((lineType, index) => (
                <div
                    key={index}
                    className={`h-1 ${
                        lineType === 'green' ? 'bg-green-500' :
                        lineType === 'red' ? 'bg-red-500' :
                        'bg-gray-300'
                    }`}
                ></div>
            ))}
        </div>
    );
}

export default StatusLines;