import { Link } from "react-router-dom";



const NotFoundPage = () => {

    return (
        <div className="min-h-screen h-full px-4 flex items-center justify-center">
            <div className="flex flex-col gap-4 items-center justify-center text-center">
                <h1 className="text-4xl">404</h1>
                <h2 className="text-2xl">Page Not Found</h2>
                <p className="text-lg">It seems that the page you are looking for does not exist.</p>
                <Link to='/' className="bg-neutral-800 text-white px-4 py-2 rounded-sm">Back to home</Link>
            </div>
        </div>
    )
}

export default NotFoundPage;