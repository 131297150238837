import home_header_banner from '../../../assets/images/general/home_header_banner.jpg'

const HomeHeader = () => {

    const divStyle = {
        backgroundImage: `url(${home_header_banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    return (
        <div className='h-[82vh]' style={divStyle}>

        </div>
    )
}

export default HomeHeader;