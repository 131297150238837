import { Link, useLocation } from 'react-router-dom';
import logo from '../../../assets/images/logos/logo.png';
import { IonIcon } from '@ionic/react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const location = useLocation();
    
    if (location.pathname === '/checkout') return null;
    return (
        <div className="bg-main">
            <div className='max-w-desktop m-auto pt-10'>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xmd:grid-cols-4  pb-6 gap-y-8">
                    <div className=''>
                        <img src={logo} alt="" className='h-12' />
                    </div>
                    <div className='flex flex-col items-start gap-2'>
                        <div className='text-xl text-semibold mb-2 text-neutral-800'>
                            Orders
                        </div>
                        <Link to='/payments' className='text-lg  text-neutral-600 cursor-pointer hover:underline duration-100' >
                            Payments
                        </Link>
                        <Link to='/shipping' className='text-lg text-neutral-600 cursor-pointer hover:underline duration-100'>
                            Shipping
                        </Link>
                        <Link to="/returns" className='text-lg text-neutral-600 cursor-pointer hover:underline duration-100'>
                            Returns
                        </Link>
                    </div>
                    <div className='flex flex-col items-start gap-2'>
                        <Link to='/privacy' className='text-xl text-semibold mb-2 text-neutral-800'>
                            Privacy
                        </Link>
                        <Link to='/terms-and-conditions' className='text-lg  text-neutral-600 cursor-pointer hover:underline duration-100' >
                            Terms & Conditions
                        </Link>
                        <Link to='/privacy-policy' className='text-lg text-neutral-600 cursor-pointer hover:underline duration-100'>
                            Privacy Policy
                        </Link>
                    </div>
                    <div className='flex flex-col items-start gap-2'>
                        <div className='text-xl text-semibold mb-2 text-neutral-800'>
                            Store
                        </div>
                        <div className='text-lg  text-neutral-600 cursor-pointer  duration-100' >
                            Kyprou 11
                        </div>
                        <div className='text-lg  text-neutral-600 cursor-pointer  duration-100' >
                            Metamorphosi, Athens
                        </div>
                        <div className='text-lg  text-neutral-600 cursor-pointer  duration-100' >
                            <IonIcon icon='call' className='text-lg' /> 210 2845 593
                        </div>
                    </div>
                </div>
                <div className='border-t border-neutral-800 flex justify-between items-center py-2'>
                    <div>
                        Monc Boutique &copy; {currentYear} 
                    </div>
                    <div className='flex gap-2'>
                        <a href="/">
                            <IonIcon icon='logo-tiktok' />
                        </a>
                        <a href="/">
                            <IonIcon icon='logo-instagram' />
                        </a>
                        <a href="/">
                            <IonIcon icon='logo-facebook' />
                        </a>
                        <a href="/">
                            <IonIcon icon='logo-pinterest' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Footer;