import { useState } from "react";
import { useParams } from "react-router-dom";
import Search from "../../Components/Search/Search";

const SearchPage = () => {
    const { search_term } = useParams<{ search_term?: string }>();

    const [activeSearch, setActiveSearch] = useState<string>(search_term ?? '');

    return (
        <div className="pt-[55px]">
            <Search searchTerm={activeSearch} />
        </div>
    );
}

export default SearchPage;