import React, { useContext } from "react";
import Bag from "../../Components/Bag/Bag";


const BagPage = () => {


    return (
        <div>
            <Bag />
        </div>
    )

}


export default BagPage;