export function getCookie(name: string) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }

    if (!cookieValue) {
        cookieValue = generateRandomString(20);
        document.cookie = `${name}=${cookieValue}; path=/; max-age=${7 * 24 * 60 * 60}`;
    }

    return cookieValue;
}

function generateRandomString(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function changeBagId(): string {
    const newBagId = generateRandomString(20);
    document.cookie = `bag_id=${newBagId}; path=/; max-age=${7 * 24 * 60 * 60}`;
    return newBagId;
}