import { useState } from "react";
import { useParams } from "react-router-dom";
import ProductSingle from "../../Components/ProductSingle/ProductSingle";


const ProductSinglePage = () => {
    const { slug } = useParams<{ slug?: string }>();

    const [activeSlug, setActiveSlug] = useState<string>(slug ?? '');
    return (
        <div className="pt-[55px]">
            <ProductSingle itemSlug={activeSlug} />
        </div>
    )
}


export default ProductSinglePage;