import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navigation/Navbar/Navbar';
import Footer from './Components/Navigation/Footer/Footer';
import HomePage from './Pages/HomePage/HomePage';
import ProductListPage from './Pages/ProductListPage/ProductListPage';
import ProductSinglePage from './Pages/ProductSinglePage/ProductSinglePage';
import BagPage from './Pages/BagPage/BagPage';
import CheckoutPage from './Pages/CheckoutPage/CheckoutPage';
import SearchPage from './Pages/SearchPage/SearchPage';
import PaymentsPage from './Pages/MiscPage/PaymentsPage/PaymentsPage';
import ShippingPage from './Pages/MiscPage/ShippingPage/ShippingPage';
import TermsAndConditionsPage from './Pages/MiscPage/TermsAndConditionsPage/TermsAndConditionsPage';
import PrivacyPolicyPage from './Pages/MiscPage/PrivacyPolicyPage/PrivacyPolicyPage';
import ReturnPage from './Pages/MiscPage/ReturnsPage/ReturnPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import OrderConfirmPage from './Pages/OrderConfirmPage/OrderConfirmPage';
import OrderCouldNotBeCompletedPage from './Pages/OrderCouldNotBeCompletedPage/OrderCouldNotBeCompletedPage';


function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/woman" element={<ProductListPage />} />
        <Route path="/item/:slug" element={<ProductSinglePage  />} />
        <Route path="/search/:search_term" element={<SearchPage  />} />
        <Route path="/bag" element={<BagPage  />} />
        <Route path="/checkout" element={<CheckoutPage  />} />

        <Route path="/payments" element={<PaymentsPage />} />
        <Route path="/order-confirmation/:order_id" element={<OrderConfirmPage />} />
        <Route path="/order-canceled/:order_id" element={<OrderCouldNotBeCompletedPage />} />
        <Route path="/order-track/:order_id" element={<OrderConfirmPage />} />

        <Route path="/shipping" element={<ShippingPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/returns" element={<ReturnPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
