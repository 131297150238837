import { IonIcon } from "@ionic/react";
import React, { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ProductDetails, ProductVariant, SingleProductProps } from "../../interfaces";
import { ACTIVE_MEDIA_URL, ACTIVE_URL } from "../../constants";
import { BagContext } from '../../utils/CartContext/CartContext';

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "../../shadcn/components/ui/carousel"

const ProductSingle: React.FC<SingleProductProps> = ({ itemSlug }) => {
    const fetched = useRef<boolean>(false);
    const [productDetails, setProductDetails] = useState<ProductDetails>({
        title: '',
        brand_name: '',
        category_name: '',
        colors: [],
        sizes: [],
        variants: [],
        on_sale: false,
        sale_price: null,
        description: '',
        color_names: []
    });
    const [activeColor, setActiveColor] = useState<string>('');
    const [activeVariant, setActiveVariant] = useState<ProductVariant>({} as ProductVariant);
    const [availableSizes, setAvailableSizes] = useState<string[]>([]);
    const [activeSize, setActiveSize] = useState<string>('');
    const [hoveredColorName, setHoveredColorName] = useState<string>('');

    const { addItem } = useContext(BagContext) || { addItem: (item_sku: string) => { } };

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/products/get_product_details/${itemSlug}/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();

                    const productDetails: ProductDetails = {
                        title: data.title,
                        brand_name: data.brand_name,
                        category_name: data.category_name,
                        colors: data.colors.map((color: { code: string }) => color.code),
                        sizes: data.sizes.map((size: { code: string }) => size.code),
                        variants: data.variants.map((variant: any) => {
                            return {
                                sku: variant.sku,
                                color: variant.color_code,
                                size: variant.size_code,
                                quantity: variant.quantity,
                                images: variant.variant_images,
                                price: variant.price
                            }
                        }),
                        on_sale: data.on_sale,
                        sale_price: data.sale_price,
                        description: data.description,
                        color_names: data.color_names
                    }
                    setProductDetails(productDetails);
                    setActiveColor(productDetails.variants[0].color);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }

        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, [itemSlug]);

    useEffect(() => {
        if (productDetails.variants.length > 0) {
            const variant = productDetails.variants.find(variant => variant.color === activeColor && variant.size === activeSize);
            if (variant) {
                setActiveVariant(variant);
            } else {
                setActiveVariant({} as ProductVariant);
            }
        }
    }, [activeColor, activeSize, productDetails.variants]);

    useEffect(() => {
        if (activeColor && productDetails.variants.length > 0) {
            const sizes = productDetails.variants.filter(variant => variant.color === activeColor).map(variant => variant.size);
            setAvailableSizes(sizes);
            setActiveSize(sizes[0] || '');
        }
    }, [activeColor, productDetails.variants]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getColorName = (colorCode: string) => {
        const colorName = productDetails.color_names.find(color => color.code === colorCode);
        return colorName ? colorName.name : '';
    }

    const handleAddToBag = () => {
        if (activeVariant && activeVariant.color && activeVariant.size) {
            addItem(activeVariant.sku, 1);
        } else {
            alert('Please select a size and color.');
        }
    }


    return (
        <div className="bg-main pb-10 sm:py-10 select-none">
            <div className="sm:max-w-[1200px] sm:px-4 m-auto flex flex-col sm:flex-row sm:flex-wrap">
                <div className="hidden sm:flex w-1/2 flex-col gap-8 items-end">

                    {activeVariant.images ? activeVariant.images.map((image, index) => {
                        return <img key={index} src={ACTIVE_MEDIA_URL + image} alt={productDetails.title + ' image'} className='w-full rounded-sm shadow-sm ' />
                    })
                        : null}
                </div>
                <div className="flex w-full sm:hidden items-center">
                    <Carousel className="w-full ">
                        <CarouselContent>
                            {activeVariant.images ? activeVariant.images.map((image, index) => (
                                <CarouselItem key={index}>
                                    <img src={ACTIVE_MEDIA_URL + image} alt={productDetails.title + ' image'} className='w-full rounded-sm shadow-sm ' />
                                </CarouselItem>
                            )) : null}
                        </CarouselContent>
                        <CarouselPrevious />
                        <CarouselNext />
                    </Carousel>
                </div>
                <div className="relative flex-none w-full sm:w-1/2 h-fit sm:h-auto px-4 sm:px-0">
                    <div className="py-4 sm:h-screen sm:p-[38px] sm:pl-[68px] sm:sticky sm:top-0 flex flex-col justify-center items-start z-10">
                        <div className="block w-full sm:max-w-[340px]">
                            <div className="flex justify-between items-end border-b border-neutral-400 pb-2">
                                <h2 className="text-xl">{productDetails.title}</h2>
                                <p className="text-base">&euro; {activeVariant.price}</p>
                            </div>
                            <div className="pt-2 flex gap-1 mb-[50px]">
                                <span className="cursor-pointer hover:underline">{productDetails.category_name}</span>
                                <span>|</span>
                                <span className="cursor-pointer hover:underline">{productDetails.brand_name}</span>
                            </div>
                            <div className="pb-1 border-b border-neutral-400 mb-[50px] flex justify-between">
                                <div>
                                    {productDetails.colors && productDetails.colors.map((color, index) => {
                                        return <span key={index} onMouseOver={() => setHoveredColorName(getColorName(color))} onMouseLeave={() => setHoveredColorName('')} className={`w-5 h-5 rounded-full inline-block border border-neutral-400 cursor-pointer ${activeColor === color ? 'border-neutral-700' : ''}`} style={{ backgroundColor: color }} onClick={() => setActiveColor(color)}></span>
                                    })}
                                </div>
                                <div>
                                    <span className="text-sm">{hoveredColorName === '' ? 'Color' : hoveredColorName}</span>
                                </div>
                            </div>
                            <div className=" border-b border-neutral-400 mb-[50px] flex justify-between">
                                <div className="flex text-base ">
                                    {availableSizes.map((size, index) => {
                                        return <span key={index} className={`cursor-pointer px-3 py-1 hover:underline border-r rounded-sm ${activeSize === size ? 'border border-neutral-600 underline' : ''}`} onClick={() => setActiveSize(size)}>{size}</span>
                                    })}
                                </div>
                                <div>
                                    <span className="text-sm">Size</span>
                                </div>
                            </div>
                            <div className="mb-[50px] flex flex-col gap-2">
                                <button onClick={handleAddToBag} className="w-full py-2 shadow-md bg-neutral-800 text-white rounded-sm items-center justify-center flex gap-2">
                                    <IonIcon icon="bag" className="text-lg mb-1" />
                                    Add to Bag
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSingle;
