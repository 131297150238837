import { useEffect, useState, useRef } from 'react';
import { ACTIVE_URL } from '../../../constants';
import { ProductItemGridDetails } from '../../../interfaces';
import ProductListItem from '../ProductListItem/ProductListItem';
import { IonIcon } from '@ionic/react';

const ProductListGrid = () => {
    const fetched = useRef<boolean>(false);
    const [productList, setProductList] = useState<ProductItemGridDetails[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<ProductItemGridDetails[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [selectedColor, setSelectedColor] = useState<string>('');
    const [selectedSize, setSelectedSize] = useState<string>('');
    const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
    const [sortOption, setSortOption] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/products/get_all_products/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    const products = data.map((product: any) => ({
                        title: product.title,
                        image: product.image,
                        brand_name: product.brand_name,
                        category_name: product.category_name,
                        colors: product.colors.map((color: { code: string }) => color.code),
                        sizes: product.sizes.map((size: { code: string }) => size.code),
                        price: product.price,
                        on_sale: product.on_sale,
                        sale_price: product.sale_price,
                        slug: product.slug
                    }));
                    setProductList(products);
                    setFilteredProducts(products);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Fetch data once
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let filtered = productList;

        if (selectedCategory) {
            filtered = filtered.filter(product => product.category_name === selectedCategory);
        }

        if (selectedColor) {
            filtered = filtered.filter(product => product.colors.includes(selectedColor));
        }

        if (selectedSize) {
            filtered = filtered.filter(product => product.sizes.includes(selectedSize));
        }

        if (sortOption) {
            filtered = [...filtered].sort((a, b) => {
                if (sortOption === 'priceAsc') {
                    return a.price - b.price;
                } else if (sortOption === 'priceDesc') {
                    return b.price - a.price;
                }
                return 0;
            });
        }

        setFilteredProducts(filtered);
    }, [selectedCategory, selectedColor, selectedSize, sortOption, productList]);

    const handleCategoryChange = (category: string) => {
        setSelectedCategory(category === selectedCategory ? '' : category);
    };

    const handleColorChange = (color: string) => {
        setSelectedColor(color === selectedColor ? '' : color);
    };

    const handleSizeChange = (size: string) => {
        setSelectedSize(size === selectedSize ? '' : size);
    };

    const handleSortChange = (option: string) => {
        setSortOption(option === sortOption ? '' : option);
    };

    const availableCategories = Array.from(new Set(productList
        .filter(product => !selectedColor || product.colors.includes(selectedColor))
        .filter(product => !selectedSize || product.sizes.includes(selectedSize))
        .map(product => product.category_name)));

    const availableColors = Array.from(new Set(productList
        .filter(product => !selectedCategory || product.category_name === selectedCategory)
        .filter(product => !selectedSize || product.sizes.includes(selectedSize))
        .flatMap(product => product.colors)));

    const availableSizes = Array.from(new Set(productList
        .filter(product => !selectedCategory || product.category_name === selectedCategory)
        .filter(product => !selectedColor || product.colors.includes(selectedColor))
        .flatMap(product => product.sizes)));

    return (
        <div className='min-h-screen bg-main'>
            <div className='max-w-desktop m-auto'>
                <div className='py-7 w-full select-none'>
                    <div className='flex gap-1 items-center justify-center cursor-pointer hover:underline' onClick={() => { setFiltersOpen(!filtersOpen) }}>
                        <h4 className='text-lg'>Filters</h4>
                        {
                            filtersOpen ?
                                <IonIcon icon='remove' className='z-0' /> :
                                <IonIcon icon='add' className='z-0' />
                        }

                    </div>
                    {
                        filtersOpen ?
                            <div className='flex gap-4 sm:gap-3 md:gap-2 items-center justify-center py-4 flex-col'>
                                <div className='flex gap-2 items-center'>
                                    <h5 className='text-sm'>CATEGORY:</h5>
                                    {availableCategories.map(category => (
                                        <span
                                            key={category}
                                            className={`cursor-pointer ${selectedCategory === category ? 'underline' : ''}`}
                                            onClick={() => handleCategoryChange(category)}
                                        >
                                            {category}
                                        </span>
                                    ))}
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <h5 className='text-sm'>SIZES:</h5>
                                    {availableSizes.map(size => (
                                        <span
                                            key={size}
                                            className={`cursor-pointer ${selectedSize === size ? 'underline' : ''}`}
                                            onClick={() => handleSizeChange(size)}
                                        >
                                            {size}
                                        </span>
                                    ))}
                                </div>
                                <div className='flex gap-1.5 items-center'>
                                    <h5 className='text-sm'>COLORS:</h5>
                                    {availableColors.map(color => (
                                        <span
                                            key={color}
                                            className={`cursor-pointer  bg-[${color}] h-4 w-4 rounded-full border ${selectedColor === color ? 'border-neutral-500' : ''}`}
                                            onClick={() => handleColorChange(color)}
                                        >
                                        </span>
                                    ))}
                                </div>
                                <div className='flex gap-1.5 items-center'>
                                    <h5 className='text-sm'>SORT BY:</h5>
                                    <span
                                        className={`cursor-pointer  ${sortOption === 'priceAsc' ? 'underline' : ''}`}
                                        onClick={() => handleSortChange('priceAsc')}
                                    >
                                        Price Ascending
                                    </span>
                                    <span
                                        className={`cursor-pointer ${sortOption === 'priceDesc' ? 'underline' : ''}`}
                                        onClick={() => handleSortChange('priceDesc')}
                                    >
                                        Price Descending
                                    </span>
                                </div>
                            </div>
                            : null
                    }
                </div>

                <div className='w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6'>
                    {filteredProducts.map((product, index) => (
                        <ProductListItem key={index} {...product} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ProductListGrid;
