import { useContext } from 'react';
import { BagContext } from '../../utils/CartContext/CartContext';
import { Link } from 'react-router-dom';
import { ACTIVE_MEDIA_URL } from '../../constants';
import { IonIcon } from '@ionic/react';
import visaImg from '../../assets/images/general/visa-icon.png';
import mcImg from '../../assets/images/general/mastercard-icon.png';

const Bag = () => {
    const { numItems, bagItems, addedItem, reduceQuantity, increaseQuantity, removeItem, bagId, bagInfo } = useContext(BagContext) || { numItems: 0 };

    if (numItems === 0) {
        return (
            <div className='min-h-screen bg-main flex items-center justify-center select-none flex flex-col gap-4'>
                <h3 className='text-lg'>Your shopping bag is empty</h3>
                <Link to='/'>
                    <button className='bg-black text-white px-4 py-2 rounded-sm'>Continue Shopping</button>
                </Link>
            </div>
        )
    }

    console.log(bagInfo)


    return (
        <div className="min-h-screen bg-main w-full pt-[70px] sm:pt-[55px] ">
            <div className='max-w-desktop m-auto grid grid-cols-1 sm:grid-cols-[2.9fr_1fr] gap-10 pt-10'>
                <div className=''>
                    <h3 className='text-center sm:text-left border-b border-neutral-800 pb-2 text-base mb-2'>YOUR SELECTIONS</h3>
                    {
                        bagItems && bagItems.map((item, index) => (
                            <div key={index} className='grid sm:grid-cols-[1fr_2.1fr_1.1fr_0.8fr] gap-4 pb-2 mb-2 border-b border-neutral-800'>
                                <div className='w-full sm:w-auto flex items-center justify-center'>
                                    <img src={ACTIVE_MEDIA_URL + item.image_url} alt={item.name} className="rounded-sm sm:max-w-screen max-h-[230px]" />
                                </div>
                                <div className='flex flex-col h-full justify-between text-center sm:text-left'>
                                    <div className='text-sm h-full'>
                                        <div className='text-lg mb-2'>
                                            {item.name}
                                        </div>
                                        <div className='mb-1 sm:mb-0'>
                                            Brand: {item.brand}
                                        </div>
                                        <div className='mb-1 sm:mb-0'>
                                            Style: {item.item_sku}
                                        </div>
                                        <div className='mb-1 sm:mb-0'>
                                            Variant: {item.color}
                                        </div>
                                        <div className='mb-1 sm:mb-0'>
                                            Size: {item.size}
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-end mt-6 sm:my-0'>
                                        <div>
                                            AVAILABLE
                                        </div>
                                        <div>
                                            Estimated shipping within 3-5 business days.
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col h-full sm:justify-between text-center gap-4 sm:gap-2 items-center sm:text-left sm:gap-0 my-4 sm:my-0'>
                                    <div className='flex gap-1 items-center justify-start'>
                                        <div>
                                            Quantity:
                                        </div>
                                        <div
                                            className='h-3 w-3 rounded-full border border-neutral-800 flex items-center justify-center cursor-pointer'
                                            onClick={() => increaseQuantity && increaseQuantity(item, 1)}
                                        >
                                            <IonIcon icon="add" />
                                        </div>
                                        <div
                                            className={`h-3 w-3 rounded-full ${item.quantity === 1 ? 'text-neutral-400 border-neutral-400' : 'text-neutral-800 border-neutral-800'} border  flex items-center justify-center cursor-pointer`}
                                            onClick={() => item.quantity > 1 && reduceQuantity && reduceQuantity(item, 1)}
                                        >
                                            <IonIcon icon="remove" />
                                        </div>
                                        {item.quantity}

                                    </div>
                                    <div
                                        className="text-base text-neutral-400 hover:text-red-500 cursor-pointer underline"
                                        onClick={() => removeItem && removeItem(item)}
                                    >
                                        Remove
                                    </div>
                                </div>
                                <div className='w-full flex justify-center sm:justify-end px-5'>
                                    <div>
                                        &euro; {item.price.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
                <div className='flex flex-col-reverse sm:flex-col gap-4 mb-12'>
                    <div className='border border-neutral-800 p-4 flex flex-col rounded-sm'>
                        <div className='text-lg mb-2'>
                            ORDER SUMMARY
                        </div>
                        <div className='text-sm pb-0.5 border-b border-neutral-500'>
                            Order Id: {bagId}
                        </div>
                        <div className='my-4'>
                            <div className='w-full flex justify-between'>
                                <div>
                                    Subtotal:
                                </div>
                                <div>
                                    &euro; {bagInfo && bagInfo.subtotal && bagInfo.subtotal.toFixed(2)}
                                </div>
                            </div>
                            <div className='w-full flex justify-between'>
                                <div>
                                    Shipping:
                                </div>
                                <div>
                                    &euro; {bagInfo && bagInfo.shipping && bagInfo.shipping.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className='py-4'>
                            <div className='w-full flex justify-between'>
                                <div>
                                    Total:
                                </div>
                                <div>
                                    &euro; {bagInfo && bagInfo.total && bagInfo.total.toFixed(2)}
                                </div>
                            </div>
                            <div className='w-full flex justify-between'>
                                <div>
                                    VAT:
                                </div>
                                <div>
                                    &euro; {bagInfo && bagInfo.vat && bagInfo.vat.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <Link to='/checkout' className='w-full'>
                            <button className='bg-black text-white px-4 py-2 mt-2 rounded-sm w-full flex items-center justify-center'>
                                <IonIcon icon="bag-check" className='mr-2' />
                                To Checkout
                            </button>
                        </Link>
                    </div>

                    <div className='border border-neutral-800 p-4 flex flex-col rounded-sm'>
                        <div className='text-base pb-0.5 border-b border-neutral-500 mb-4'>
                            MORE INFORMATION
                        </div>

                        <div className=''>
                            CONTACT
                        </div>
                        <div className='py-2'>
                            <div className='w-full flex justify-between'>
                                <IonIcon icon='call' />
                                <div>
                                    +30 210 2845 593
                                </div>
                            </div>
                            <div className='w-full flex justify-between'>
                                <IonIcon icon='mail' />
                                <div>
                                    info@moncboutique.gr
                                </div>
                            </div>
                        </div>
                        <div>
                            PAYMENT METHODS
                        </div>
                        <div className='py-2'>
                            <div className='w-full flex justify-between'>
                                <img src={visaImg} alt="" className='h-4' />
                                <div>
                                    Visa
                                </div>
                            </div>
                            <div className='w-full flex justify-between'>
                                <img src={mcImg} alt="" className='h-4' />
                                <div>
                                    Master Card
                                </div>
                            </div>
                        </div>
                        <div>
                            SHIPPING OPTIONS
                        </div>
                        <div className='py-2'>
                            <div className='w-full flex justify-between'>

                                <div>
                                    ELTA Courier
                                </div>
                                &euro; 0.00
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Bag;