import { useEffect } from "react";
import Payments from "../../../Components/Misc/Payments/Payments";



const PaymentsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="pt-[55px]">
            <Payments />
        </div>
    )
}

export default PaymentsPage;