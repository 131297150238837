import { Link } from 'react-router-dom';
import activeSeasonImage from '../../../assets/images/general/summer_2024.jpg';

const HomeActiveSeason = () => {


    return (
        <div className="bg-main py-10 md:py-6" >
            <div className="max-w-desktop m-auto grid md:grid-cols-2 ">
                <div className='h-fit items-center justify-center flex md:pb-0 pb-6'> 
                    <img src={activeSeasonImage} alt=""  className=' rounded-sm w-full h-auto shadow-md'/>
                </div>
                <div className='flex items-center justify-center flex-col gap-6 sm:gap-8 md:gap-10 text-center'>
                    <h2 className='text-2xl sm:text-3xl md:text-4xl'>Spring / Summer 2024 <br></br> Collection</h2>
                    <div className='text-lg md:text-xl'>
                        The new collection is here. Explore your favorite brands.
                    </div>
                    <Link to='/woman' className="bg-neutral-800 text-neutral-100 px-4 py-2  inline-block text-base md:text-lg rounded-sm">
                        Discover More
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default HomeActiveSeason;