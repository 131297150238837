

const PrivacyPolicy = () => {


    return (
        <div className="min-h-screen max-w-desktop m-auto py-12">
            <h3 className="text-3xl mb-10">
                Privacy Policy
            </h3>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    1. Who collects your personal data?
                </h4>
                <div>
                    The Greek company OLGA KIOURKENIDI is responsible for the processing of personal data provided to us and is responsible for the security of your data under the Personal Data Protection Act.
                </div>
                <div>
                    Company name: OLGA KIOURKENIDI
                </div>
                <div>
                    VAT: 074768935
                </div>
                <div>
                    Kyprou 11, Metamorphosi, Athens
                </div>
                <div>
                    tel: 210 2845 593
                </div>
                <div>
                    email: info@moncboutique.gr
                </div>
            </div>

            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    2. Why do we use your personal data?
                </h4>
                <ul>
                    <li>
                        to manage your online purchase, that is to process your orders and returns through our online services and to send you notifications about the delivery status or in case of any problems during the delivery of your items.
                    </li>
                    <li>
                        to process your payments.
                    </li>
                    <li>
                        to manage complaints about our products.
                    </li>
                    <li>
                        to send promotional emails about new products, special offers, our news or other information that we think may be useful to you, using the email addresses you have given us.
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    3. What personal data do we collect?
                </h4>
                <ul>
                    <li>
                        Contact details such as name, surname, address, e-mail address, telephone number, postal code.
                    </li>
                    <li>
                        Account information such as name, surname, address, email address, telephone number, postcode, date of birth, gender, city, country, and username.
                    </li>
                    <li>
                        Information and payment history
                    </li>
                    <li>
                        Order information
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    4. What is the legal basis for processing your data?
                </h4>
                <div>
                    We process your personal data because it is essential for:
                </div>
                <ul>
                    <li>
                        the execution of the contract of sale of goods.
                    </li>
                    <li>
                        our compliance with our legal obligations.
                    </li>
                    <li>
                        pursuing our legitimate interests.
                    </li>
                </ul>
                <div>
                    In general, we rely on your consent as a legal basis only for the processing related to the sending of direct marketing messages via e-mail.
                    You have the right to withdraw your consent at any time. Your consent is the sole legal basis for processing, and we will stop processing your data after it is revoked.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    5. How long do we keep your data?
                </h4>
                <div>
                    We do not retain your data for longer than is necessary for the purposes set out in this Policy.
                    Different retention periods apply to different types of data, however the longest time we keep your personal data is 10 years.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    6. Who has access to your personal data?
                </h4>
                <div>
                    In order to provide you with certain services, we need to share your personal information with some of our partners.
                    These include delivery, payment and marketing service providers. We will never transfer, sell, rent or exchange our customers’ data to other organizations for marketing purposes.
                    We may share your data with government agencies, regulators, law enforcement agencies, courts, banking institutions and insurers, in cases where we have to do so:
                </div>
                <ul>
                    <li>
                        to comply with our legal obligations.
                    </li>
                    <li>
                        to exercise our legal rights.
                    </li>
                    <li>
                        to prevent, locate, investigate crimes, or prosecute offenders.
                    </li>
                    <li>
                        to protect our employees and customers.
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    7. Do we forward your data outside the European Union?
                </h4>
                <div>
                    No, we do not forward your data outside the European Union.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    8. How do we protect your data?
                </h4>
                <div>
                    We implement appropriate technical and organizational measures to ensure that your personal data is always protected and secure. Our security measures include data encryption,
                    regular cybersecurity assessments by all service providers that may handle your personal data, security checks that protect our entire technology
                    infrastructure from external attack and unauthorized access, and internal policies that determine how to ensure the protection of your data and the training of our employees.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    9. What are your rights?
                </h4>
                <ul>
                    <li>
                        Right of access: you have the right to request information about personal data that we process at any time. You can contact us and we are willing to let you know your personal information via email.
                    </li>
                    <li>
                        Right to correction: You have the right to request the correction of your personal data if it is incorrect.
                    </li>
                    <li>
                        Right to delete (“right to be forgotten”): You have the right to request the deletion of personal data processed by our company at any time, unless an order is pending which has not yet been shipped or you have any debt to us.
                    </li>
                    <li>
                        Right to restrict processing: You have the right to ask us to restrict processing when there is a specific reason for it (e.g. if you have objections to our legal interest or if your personal data is incorrect or if you think the processing is illegal or if we no longer need them).
                    </li>
                    <li>
                        Right to portability: Every time our company processes your personal data, you have the right to get a copy of them in a structured, commonly used and machine readable format, as well as request that they be transferred elsewhere. This only includes the personal data you provided to us.
                    </li>
                    <li>
                        Right to object against processing based on legal interest: You have the right to object to the processing of your personal data based on our legal interest. We will not continue to process your personal data unless we can prove legitimate grounds for processing that are in your best interests or have legitimate claims.
                    </li>
                    <li>
                        Right to oppose direct commercial promotion: You have the right to object to direct commercial promotion, including the preparation of profiles made for direct commercial purposes. You have the option to opt out of direct marketing.
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    10. How can you exercise your rights?
                </h4>
                <div>
                    If you wish to exercise any of the above rights, contact us using our contact details or go to your personal account where you can change your data or delete it. If you believe that our company is processing your personal data incorrectly, please contact us.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    11. Can we change the privacy policy?
                </h4>
                <div>
                    We reserve the right to update or change the Privacy Policy at any time. You should check this Policy periodically. The latest version of the Privacy Policy is always available on our website. We will notify any substantial changes to the Privacy Policy, for example if they concern the purpose for which we use your personal data, the identity of the controller or your rights.
                </div>
            </div>
            <h3 className="text-3xl my-10">
                Cookies Policy
            </h3>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    1. What are cookies?
                </h4>
                <div>
                    We use cookies to collect information. A cookie is a small text file that is sent from a website and stored on your computer by the browser, while browsing the Internet. If you use our services, you consent to the use of cookies. Cookies help us remember who you are and other information about your visits. Cookies are essential for the effective operation of our website and to help you in your online purchase.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    2. What kind of cookies do we use and for what purpose?
                </h4>
                <div>
                    We use necessary first – party cookies (such as session, permanent and traffic log cookies). These cookies collect information about your browsing and shopping behavior when you access this website through your computer or other device. The information collected is related to the pages you saw, your favorite products or products you purchased, and your navigation on our website. In addition to first-party cookies, we use third-party cookies to collect user statistics and data, in aggregate and individual form, through analysis tools to optimize our website and provide you with relevant promotional material.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    3. What personal data could cookies record?
                </h4>
                <ul>
                    <li>
                        Cookie ID
                    </li>
                    <li>
                        The IP address
                    </li>
                    <li>
                        The browsing history
                    </li>
                    <li>
                        Buying behavior
                    </li>
                    <li>
                        If you have an account, we can also link your cookie ID to the personal data you submitted to us when you created your account.
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    4. How long do we store your personal data?
                </h4>
                <div>
                    We do not retain your data more than is necessary for the purposes set out in this Policy. The data recorded by the first – party cookies are kept for only 30 days. In addition, data from third-party cookies is stored for 26 months.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    5. Who has access to your personal data?
                </h4>
                <div>
                    Data transmitted to third parties (such as Google Analytics) is only used to provide the above services and to collect statistical data through analysis tools to optimize our website and provide you with relevant promotional material.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    6. What is the legal basis for the use of cookies?
                </h4>
                <div>
                    The use of cookies is based only on your clear consent. You have the right to withdraw your consent at any time. Please note that if you revoke your consent, you will not be able to access certain areas or functions of our website.
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-6">
                <h4 className="font-semibold">
                    7. How can you disable cookies?
                </h4>
                <div>
                    You can easily disable cookies using your browser. If you want to manage and delete cookies, see the “help” and “support” section of your browser. You can choose to disable cookies or receive a notification every time a new cookie is sent to your computer. Please note that by deleting cookies or disabling future cookies you may not have access to specific areas or functions of our website.
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;